
// ng bootstrap accordion
.ngb-accordion{
  .card{
    border-bottom:none;
    border-top:1px solid rgba(0, 0, 0, 0.125);
    box-shadow: none;
    margin-bottom: 0;
    border-radius: 0;
    .card-header{
      border-radius: 0;
      border-bottom: none;
      padding: 14px 20px;
      box-shadow: none;
      a{
        transition: all 0.5s ease-in-out;
        font-weight: 500;
      }
      &:hover,&:focus{
        a{
          color: $color-blue;
          background: #fff;
          &:hover,&:focus{
            background: #fff;
          }
        }
      }
    }
  }
  &.color-accordion {
    .card{
      border-top:1px solid #fff;
      .card-header{
        transition: all 0s ease-in-out;
        a{
          transition: all 0s ease-in-out;
        }
        &:hover,&:focus,&.active{
          background: $color-blue;
          a{
            color: #fff;
            background: $color-blue;
            &:hover,&:focus{
              color: #fff;
              background: $color-blue;
            }
          }
        }
      }
    }
  }
}

.pcoded[layout-type="dark"], body.dark {
  .ngb-accordion .card .card-header:hover a, .ngb-accordion .card .card-header:focus a {
    background-color: transparent !important;
  }

  .ngb-accordion.color-accordion {
    .card {
      border-top: inherit;
    }
  }
}
