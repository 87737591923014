/**  =====================
      Draggable css start
==========================  **/

#draggableMultiple .sortable-moves {
    cursor: move;
    margin-bottom: 0;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.14);
    margin-bottom: 20px;
    padding: 15px 0 15px 60px;
}

.sortable-moves {
    font-size: 14px;
    line-height: 1.55556em;
    list-style-type: none;
    margin-bottom: 15px;
    min-height: 3.55556em;
    padding-left: 5.11111em;
    position: relative;
    cursor: move;
    img {
        position: absolute;
        height: 40px;
        left: 10px;
        border-radius: 5px;
        top: 15px;
    }
    h6 {
        font-weight: 500;
    }
}

.card-sub {
    cursor: move;
    border: none;
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.05), 0 -2px 1px -2px rgba(0, 0, 0, 0.04), 0 0 0 -1px rgba(0, 0, 0, 0.05);
    &:hover {
        box-shadow: 0 0 25px -5px #9e9c9e;
        transition: all 180ms linear;
    }
}


/**====== Draggable css end ======**/
