/**
Template Name: Material Able Admin Template
Author: #
Email: #
File: style.css
=============
  == Table of Contents==
	- Custom Css
	- General Elements
		-	Modal, Nestable, List, Portlates, Preloader, Calender, To-Do, Materialize general elements, Lables
	- Advance Form Elements
	- Pages
		-	Loader page, Invoice, Tasklist, Task detail, Range-slider, Counter, Maintenance, Color, FAQ,  Panels & wells, Contact card, Auth pages, Flag, Gallery, Products pages, Widget, Email, Social timeline, Datatable, Chat, Blog, Wizard, CRM dashboard, Task board, Issue list, Chartlist, X-editable
============= */
/**  =====================
      Box-shadow css start
==========================  **/
.box-list div div div {
  margin-bottom: 20px; }

.box-list {
  padding-bottom: 0; }

.box-list p {
  margin-bottom: 0 !important; }

/**====== z-depth-top css start ======**/
.z-depth-top-0 {
  box-shadow: 0 -5px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-top-1 {
  box-shadow: 0 -7px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-top-2 {
  box-shadow: 0 -9px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-top-3 {
  box-shadow: 0 -10px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-top-4 {
  box-shadow: 0 -11px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-top-5 {
  box-shadow: 0 -12px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

/**====== z-depth-top css end ======**/
/**====== z-depth-bottom css start ======**/
.z-depth-bottom-0 {
  box-shadow: 0 5px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-bottom-1 {
  box-shadow: 0 7px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-bottom-2 {
  box-shadow: 0 9px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-bottom-3 {
  box-shadow: 0 10px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-bottom-4 {
  box-shadow: 0 11px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-bottom-5 {
  box-shadow: 0 12px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

/**====== z-depth-bottom css end ======**/
/**====== z-depth-left css start ======**/
.z-depth-left-0 {
  box-shadow: -5px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-left-1 {
  box-shadow: -7px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-left-2 {
  box-shadow: -9px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-left-3 {
  box-shadow: -10px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-left-4 {
  box-shadow: -11px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-left-5 {
  box-shadow: -12px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

/**====== z-depth-left css end ======**/
/**====== z-depth-right css start ======**/
.z-depth-right-0 {
  box-shadow: 5px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-right-1 {
  box-shadow: 7px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-right-2 {
  box-shadow: 9px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-right-3 {
  box-shadow: 10px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-right-4 {
  box-shadow: 11px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-right-5 {
  box-shadow: 12px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

/**====== z-depth-right css end ======**/
/**====== z-depth-all-side css start ======**/
.z-depth-0 {
  box-shadow: 0 0 25px 5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-1 {
  box-shadow: 0 0 25px 7px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-2 {
  box-shadow: 0 0 25px 9px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-3 {
  box-shadow: 0 0 25px 10px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-4 {
  box-shadow: 0 0 25px 11px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-5 {
  box-shadow: 0 0 25px 12px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

/**====== z-depth-all-side css end ======**/
/**====== Box-shadow css end ======**/
/* ======================
    All Icon Common Page css
======================== */
.data-table-main.icon-list-demo [class*=col-] {
  margin-bottom: 10px; }

.data-table-main.icon-svg-demo [class*=col-] {
  margin-bottom: 10px; }

.icon-list-demo i {
  border: 1px solid #eceeef;
  border-radius: 3px;
  color: rgba(43, 61, 81, 0.7);
  display: inline-block;
  font-size: 24px;
  height: 50px;
  line-height: 50px;
  margin: 0 12px 0 0;
  text-align: center;
  vertical-align: middle;
  width: 50px; }

.icon-list-demo div {
  cursor: pointer;
  white-space: nowrap;
  margin-bottom: 10px; }

.icon-list-demo i:hover {
  color: #64b0f2; }

/**====== Flag-icon css start ======**/
.flags .f-item {
  padding: 12px;
  border: 1px solid #ddd;
  margin-right: 15px;
  display: inline-block; }

.data-table-main.flags [class*=col-] {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }

.content-flag label {
  margin-bottom: 0;
  cursor: pointer; }

.content-flag .txt-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 120px;
  overflow: hidden; }

.outer-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  width: 250px; }

label.txt-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  width: 135px; }

.flags .f-item .name,
.flags .f-item .capital {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 0; }

.flags .f-item .code {
  display: block;
  font-size: 90%;
  color: #999;
  padding-bottom: 0; }

/**====== All icon common page css end ======**/
/**  =====================
      Ready-to-use css start
==========================  **/
.bg-pic {
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: overlay; }

.j-pro {
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.15); }
  .j-pro .j-header {
    background-color: #448aff;
    border-top: 1px solid #448aff;
    box-shadow: none; }
  .j-pro .j-icon-right {
    border-left: 1px solid rgba(0, 0, 0, 0.32); }
  .j-pro .j-icon-left {
    border-right: 1px solid rgba(0, 0, 0, 0.32); }
  .j-pro .j-footer {
    background-color: #fff;
    border-top: 1px dashed #448aff;
    padding: 20px 25px;
    position: inherit; }
    .j-pro .j-footer button {
      margin-bottom: 0;
      float: right; }
  .j-pro .j-divider-text span {
    color: #222; }
  .j-pro .btn-primary.disabled,
  .j-pro .btn-primary:disabled {
    background-color: #9ccc65;
    border-color: #9ccc65; }

.j-pro input[type="text"],
.j-pro input[type="password"],
.j-pro input[type="email"],
.j-pro input[type="search"],
.j-pro input[type="url"],
.j-pro textarea,
.j-pro select,
.j-forms input[type="text"],
.j-forms input[type="password"],
.j-forms input[type="email"],
.j-forms input[type="search"],
.j-forms input[type="url"],
.j-forms textarea,
.j-forms select {
  border: 1px solid rgba(0, 0, 0, 0.15); }

.j-pro input[type="text"]:hover,
.j-pro input[type="password"]:hover,
.j-pro input[type="email"]:hover,
.j-pro input[type="search"]:hover,
.j-pro input[type="url"]:hover,
.j-pro textarea:hover,
.j-pro select:hover,
.j-pro input[type="text"]:focus,
.j-pro input[type="password"]:focus,
.j-pro input[type="email"]:focus,
.j-pro input[type="search"]:focus,
.j-pro input[type="url"]:focus,
.j-pro textarea:focus,
.j-pro select:focus,
.j-pro .j-file-button:hover + input,
.j-forms input[type="text"]:hover,
.j-forms input[type="password"]:hover,
.j-forms input[type="email"]:hover,
.j-forms input[type="search"]:hover,
.j-forms input[type="url"]:hover,
.j-forms textarea:hover,
.j-forms select:hover,
.j-forms input[type="text"]:focus,
.j-forms input[type="password"]:focus,
.j-forms input[type="email"]:focus,
.j-forms input[type="search"]:focus,
.j-forms input[type="url"]:focus,
.j-forms textarea:focus,
.j-forms select:focus {
  border: 1px solid #448aff; }

.j-pro .j-radio-toggle,
.j-pro .j-checkbox-toggle,
.j-pro .j-inline-group .j-radio-toggle,
.j-pro .j-inline-group .j-checkbox-toggle {
  padding: 9px 0 18px 0; }

.j-pro .j-tooltip,
.j-pro .j-tooltip-image {
  background-color: #448aff; }
  .j-pro .j-tooltip:before,
  .j-pro .j-tooltip-image:before {
    border-color: #448aff transparent; }

.j-pro .j-primary-btn,
.j-pro .j-file-button,
.j-pro .j-secondary-btn,
.j-pro .j-widget .j-addon-btn {
  background-color: #448aff; }
  .j-pro .j-primary-btn:hover,
  .j-pro .j-file-button:hover,
  .j-pro .j-secondary-btn:hover,
  .j-pro .j-widget .j-addon-btn:hover {
    background-color: #5e9aff; }

.j-pro .j-ratings input + label:hover,
.j-pro .j-ratings input + label:hover ~ label,
.j-pro .j-ratings input:checked + label,
.j-pro .j-ratings input:checked + label ~ label {
  color: #448aff; }

.j-unit .checkbox-fade {
  display: block;
  margin-top: 20px; }

.j-forms {
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.15); }
  .j-forms button i {
    margin-right: 0; }
  .j-forms .checkbox-fade {
    margin-top: 10px; }
    .j-forms .checkbox-fade .disabled-view {
      opacity: 0.5;
      cursor: not-allowed; }
  .j-forms .link {
    color: #448aff;
    border-bottom: none;
    text-transform: capitalize;
    margin-bottom: 15px; }
  .j-forms .label {
    margin-bottom: 15px; }
  .j-forms .btn-primary:disabled {
    background-color: #d6d6d6;
    border-color: #d6d6d6; }
  .j-forms .radio-toggle,
  .j-forms .checkbox-toggle,
  .j-forms .inline-group .radio-toggle,
  .j-forms .inline-group .checkbox-toggle {
    padding: 9px 0 8px 0px; }
  .j-forms .header {
    background-color: #448aff;
    border-top: 1px solid #448aff;
    box-shadow: none; }
  .j-forms .divider-text span {
    color: #222; }
  .j-forms .widget .addon,
  .j-forms .widget .addon-btn {
    background-color: #448aff;
    color: #fff; }
    .j-forms .widget .addon:hover, .j-forms .widget .addon:focus,
    .j-forms .widget .addon-btn:hover,
    .j-forms .widget .addon-btn:focus {
      background-color: #5e9aff; }
      .j-forms .widget .addon:hover i, .j-forms .widget .addon:focus i,
      .j-forms .widget .addon-btn:hover i,
      .j-forms .widget .addon-btn:focus i {
        color: #fff; }
    .j-forms .widget .addon i,
    .j-forms .widget .addon-btn i {
      color: #fff; }
  .j-forms .footer {
    background-color: #fff;
    border-top: 1px dashed #1abc9c;
    padding: 20px 25px;
    position: inherit; }
    .j-forms .footer button {
      float: right;
      margin-bottom: 0; }
  .j-forms .stepper .stepper-arrow {
    background-color: #448aff; }
    .j-forms .stepper .stepper-arrow:hover {
      background-color: #5e9aff; }
    .j-forms .stepper .stepper-arrow.up:after {
      border-bottom: 7px solid #fff; }
    .j-forms .stepper .stepper-arrow.down:after {
      border-top: 7px solid #fff; }

.popup-menu {
  padding: 0; }
  .popup-menu .popup-list {
    background: #448aff;
    border-radius: 0; }
    .popup-menu .popup-list > ul > li {
      transition: all ease-in .3s;
      color: #fff;
      border-left: none;
      cursor: pointer; }
      .popup-menu .popup-list > ul > li:hover {
        background-color: #384c5f;
        color: #fff; }

.j-tabs-container .j-tabs-label,
.j-tabs-container input[type="radio"]:checked + .j-tabs-label {
  border-top: 4px solid #448aff; }

.pop-up-logo img {
  margin-top: 10px;
  margin-left: 80px; }

.popup-list-open .popup-list-wrapper {
  z-index: 99; }

.span4 label {
  color: #222 !important; }

.pop-up-wrapper {
  margin-left: 0 !important; }

@media only screen and (max-width: 480px) {
  .j-forms, .j-pro {
    border: none;
    background-color: #fff; }
    .j-forms .footer, .j-forms .j-footer, .j-pro .footer, .j-pro .j-footer {
      border-top: none; }
      .j-forms .footer button, .j-forms .j-footer button, .j-pro .footer button, .j-pro .j-footer button {
        margin: 5px; }
    .j-forms .j-content, .j-pro .j-content {
      padding: 0; }
    .j-forms .j-divider-text, .j-pro .j-divider-text {
      border-top: none; }
      .j-forms .j-divider-text span, .j-pro .j-divider-text span {
        white-space: unset;
        background: transparent;
        border: none; }
    .j-forms .icon-right ~ input, .j-forms .j-forms .icon-right ~ textarea, .j-pro .icon-right ~ input, .j-pro .j-forms .icon-right ~ textarea {
      padding-right: 0; }
  .j-wrapper {
    padding: 0;
    border: none;
    background-color: transparent; } }

/**====== Ready-to-use css end ======**/
/**  =====================
      Alert css start
==========================  **/
.alert {
  font-size: 15px;
  margin-bottom: 2rem; }

.alert.icons-alert {
  border-left-width: 48px; }
  .alert.icons-alert .alert-icon {
    left: 15px;
    position: relative;
    text-align: center;
    top: 0;
    z-index: 3; }
  .alert.icons-alert p {
    line-height: 21px;
    margin-bottom: 0; }

.close {
  font-size: 16px;
  margin-top: 5px; }

/*===== Primary alert ===== */
.alert-default {
  background-color: #fff;
  border-color: #d6d6d6;
  color: #d6d6d6; }

.alert-primary {
  background-color: #fff;
  border-color: #448aff;
  color: #448aff; }

.alert-success {
  background-color: #fff;
  border-color: #9ccc65;
  color: #9ccc65; }

.alert-info {
  background-color: #fff;
  border-color: #00bcd4;
  color: #00bcd4; }

.alert-warning {
  background-color: #fff;
  border-color: #ffba57;
  color: #ffba57; }

.alert-danger {
  background-color: #fff;
  border-color: #ff5252;
  color: #ff5252; }

/*===== Border alert ===== */
.border-default {
  border-color: transparent;
  border-left: 3px solid #d6d6d6;
  border-radius: 0;
  box-shadow: 0 0 1px #999;
  color: #d6d6d6; }

.border-primary {
  border-color: transparent;
  border-left: 3px solid #448aff;
  border-radius: 0;
  box-shadow: 0 0 1px #999;
  color: #448aff; }

.border-success {
  border-color: transparent;
  border-left: 3px solid #9ccc65;
  border-radius: 0;
  box-shadow: 0 0 1px #999;
  color: #9ccc65; }

.border-info {
  border-color: transparent;
  border-left: 3px solid #00bcd4;
  border-radius: 0;
  box-shadow: 0 0 1px #999;
  color: #00bcd4; }

.border-warning {
  border-color: transparent;
  border-left: 3px solid #ffba57;
  border-radius: 0;
  box-shadow: 0 0 1px #999;
  color: #ffba57; }

.border-danger {
  border-color: transparent;
  border-left: 3px solid #ff5252;
  border-radius: 0;
  box-shadow: 0 0 1px #999;
  color: #ff5252; }

/*===== Solid-background alert ===== */
.background-default {
  background-color: #d6d6d6;
  color: #fff; }

.background-primary {
  background-color: #448aff;
  color: #fff; }

.background-success {
  background-color: #9ccc65;
  color: #fff; }

.background-info {
  background-color: #00bcd4;
  color: #fff; }

.background-warning {
  background-color: #ffba57;
  color: #fff; }

.background-danger {
  background-color: #ff5252;
  color: #fff; }

.icons-alert {
  position: relative; }
  .icons-alert:before {
    color: #fff;
    content: '\f027';
    font-family: 'IcoFont' !important;
    font-size: 16px;
    left: -30px;
    position: absolute;
    top: 17px; }

[class*="alert-"] code {
  margin-left: 10px; }

/*======= Alert css ends ===========*/
/* =======================================
      Authentication-page css start
 ========================================= */
.login-block {
  margin: 30px auto; }
  .login-block .auth-box {
    margin: 20px auto 0 auto;
    max-width: 450px; }
    .login-block .auth-box .confirm h3 {
      color: #448aff;
      font-size: 34px; }
    .login-block .auth-box i.icofont-check-circled {
      font-size: 42px; }
  .login-block.offline-404 .auth-box {
    max-width: 650px; }
    .login-block.offline-404 .auth-box h1 {
      color: #2c3e50;
      font-size: 160px;
      font-weight: 500;
      letter-spacing: 5px;
      text-shadow: 3px -2px 4px rgba(128, 128, 128, 0.57); }
    @media only screen and (max-width: 992px) {
      .login-block.offline-404 .auth-box {
        margin-top: 45px; } }
    @media only screen and (max-width: 575px) {
      .login-block.offline-404 .auth-box h1 {
        font-size: 60px; }
      .login-block.offline-404 .auth-box h2 {
        font-size: 0.8rem; } }

.footer {
  background-color: #37474f;
  color: #fff;
  padding: 15px 0;
  position: fixed;
  bottom: 0;
  width: 100%; }

/**  =====================
      Breadcrumb css start
==========================  **/
.caption-breadcrumb .breadcrumb-header, .primary-breadcrumb .breadcrumb-header, .inverse-breadcrumb .breadcrumb-header, .danger-breadcrumb .breadcrumb-header, .info-breadcrumb .breadcrumb-header, .warning-breadcrumb .breadcrumb-header, .success-breadcrumb .breadcrumb-header {
  display: inline-block; }
  .caption-breadcrumb .breadcrumb-header span, .primary-breadcrumb .breadcrumb-header span, .inverse-breadcrumb .breadcrumb-header span, .danger-breadcrumb .breadcrumb-header span, .info-breadcrumb .breadcrumb-header span, .warning-breadcrumb .breadcrumb-header span, .success-breadcrumb .breadcrumb-header span {
    display: block;
    font-size: 13px;
    margin-top: 5px; }

.front-icon-breadcrumb .breadcrumb-header {
  display: inline-block; }

.front-icon-breadcrumb .big-icon {
  display: inline-block; }
  .front-icon-breadcrumb .big-icon i {
    font-size: 50px;
    margin-right: 10px;
    color: #448aff; }

.front-icon-breadcrumb .d-inline-block span {
  display: block;
  font-size: 13px;
  margin-top: 5px; }

.breadcrumb {
  background-color: transparent;
  margin-bottom: 0; }

.primary-breadcrumb, .inverse-breadcrumb, .danger-breadcrumb, .info-breadcrumb, .warning-breadcrumb, .success-breadcrumb {
  background-color: #448aff;
  color: #fff; }
  .primary-breadcrumb h5, .inverse-breadcrumb h5, .danger-breadcrumb h5, .info-breadcrumb h5, .warning-breadcrumb h5, .success-breadcrumb h5, .primary-breadcrumb a, .inverse-breadcrumb a, .danger-breadcrumb a, .info-breadcrumb a, .warning-breadcrumb a, .success-breadcrumb a, .primary-breadcrumb .breadcrumb li:last-child a, .inverse-breadcrumb .breadcrumb li:last-child a, .danger-breadcrumb .breadcrumb li:last-child a, .info-breadcrumb .breadcrumb li:last-child a, .warning-breadcrumb .breadcrumb li:last-child a, .success-breadcrumb .breadcrumb li:last-child a, .primary-breadcrumb .breadcrumb-item + .breadcrumb-item::before, .inverse-breadcrumb .breadcrumb-item + .breadcrumb-item::before, .danger-breadcrumb .breadcrumb-item + .breadcrumb-item::before, .info-breadcrumb .breadcrumb-item + .breadcrumb-item::before, .warning-breadcrumb .breadcrumb-item + .breadcrumb-item::before, .success-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    color: #fff; }

.inverse-breadcrumb {
  background-color: #37474f; }

.danger-breadcrumb {
  background-color: #ff5252; }

.info-breadcrumb {
  background-color: #00bcd4; }

.warning-breadcrumb {
  background-color: #ffba57; }

.success-breadcrumb {
  background-color: #9ccc65; }

/**====== Breadcrumb css end ======**/
/**  =====================
      Chart css start
==========================  **/
.nvd-chart {
  height: 400px; }

.peity-chart .peity {
  width: 100%;
  height: 250px; }

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px; } }

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px; } }

.morris-hover {
  position: absolute;
  min-width: 100px;
  width: 100px;
  right: 0;
  background: #fff;
  padding: 20px;
  border: 1px solid #ccc; }

/**====== Chart css end ======**/
.rickshaw_graph svg {
  width: 100% !important; }

/* =======================
   Start Color-page Css
=========================== */
.amber-colors ul li p,
.bg-danger li p,
.bg-default li p,
.bg-info li p,
.bg-primary li p,
.bg-success li p,
.bg-warning li p,
.blue-colors ul li p,
.blue-grey-colors ul li p,
.brown-colors ul li p,
.cyan-colors ul li p,
.deep-orange-colors ul li p,
.deep-purple-colors ul li p,
.green-colors ul li p,
.grey-colors ul li p,
.indigo-colors ul li p,
.light-blue-colors ul li p,
.light-green-colors ul li p,
.lime-colors ul li p,
.orange-colors ul li p,
.pink-colors ul li p,
.purple-colors ul li p,
.red-colors ul li p,
.teal-colors ul li p,
.yellow-colors ul li p,
.primary-colorr ul li p,
.success-colorr ul li p,
.info-colorr ul li p,
.warning-colorr ul li p,
.danger-colorr ul li p {
  font-size: 18px;
  padding: 10px 0;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 0; }

/**====== Accent-red-color css start ======**/
.red-colors ul li:nth-child(14) {
  background-color: #e40505; }
  .red-colors ul li:nth-child(14) p:before {
    content: "#e40505"; }

.red-colors ul li:nth-child(13) {
  background-color: #f30606; }
  .red-colors ul li:nth-child(13) p:before {
    content: "#f30606"; }

.red-colors ul li:nth-child(12) {
  background-color: #f90f0f; }
  .red-colors ul li:nth-child(12) p:before {
    content: "#f90f0f"; }

.red-colors ul li:nth-child(11) {
  background-color: #fa1d1d; }
  .red-colors ul li:nth-child(11) p:before {
    content: "#fa1d1d"; }

.red-colors ul li:nth-child(10) {
  background-color: #fa2c2c; }
  .red-colors ul li:nth-child(10) p:before {
    content: "#fa2c2c"; }

.red-colors ul li:nth-child(9) {
  background-color: #fa3b3b; }
  .red-colors ul li:nth-child(9) p:before {
    content: "#fa3b3b"; }

.red-colors ul li:nth-child(8) {
  background-color: #fb4a4a; }
  .red-colors ul li:nth-child(8) p:before {
    content: "#fb4a4a"; }

.red-colors ul li:nth-child(7) {
  background-color: #fb5959; }
  .red-colors ul li:nth-child(7) p:before {
    content: "#fb5959"; }

.red-colors ul li:nth-child(6) {
  background-color: #fb6868; }
  .red-colors ul li:nth-child(6) p:before {
    content: "#fb6868"; }

.red-colors ul li:nth-child(5) {
  background-color: #fc7777; }
  .red-colors ul li:nth-child(5) p:before {
    content: "#fc7777"; }

.red-colors ul li:nth-child(4) {
  background-color: #fc8686; }
  .red-colors ul li:nth-child(4) p:before {
    content: "#fc8686"; }

.red-colors ul li:nth-child(3) {
  background-color: #fd9595; }
  .red-colors ul li:nth-child(3) p:before {
    content: "#fd9595"; }

.red-colors ul li:nth-child(2) {
  background-color: #fda4a4; }
  .red-colors ul li:nth-child(2) p:before {
    content: "#fda4a4"; }

.red-colors ul li:nth-child(1) {
  background-color: #fdb3b3; }
  .red-colors ul li:nth-child(1) p:before {
    content: "#fdb3b3"; }

/**====== Accent-red-color css end ======**/
/**====== Accent-pink-color css start ======**/
.pink-colors ul li:nth-child(14) {
  background-color: #d4166c; }
  .pink-colors ul li:nth-child(14) p:before {
    content: "#d4166c"; }

.pink-colors ul li:nth-child(13) {
  background-color: #e21873; }
  .pink-colors ul li:nth-child(13) p:before {
    content: "#e21873"; }

.pink-colors ul li:nth-child(12) {
  background-color: #e7217b; }
  .pink-colors ul li:nth-child(12) p:before {
    content: "#e7217b"; }

.pink-colors ul li:nth-child(11) {
  background-color: #e92f83; }
  .pink-colors ul li:nth-child(11) p:before {
    content: "#e92f83"; }

.pink-colors ul li:nth-child(10) {
  background-color: #ea3d8b; }
  .pink-colors ul li:nth-child(10) p:before {
    content: "#ea3d8b"; }

.pink-colors ul li:nth-child(9) {
  background-color: #ec4b94; }
  .pink-colors ul li:nth-child(9) p:before {
    content: "#ec4b94"; }

.pink-colors ul li:nth-child(8) {
  background-color: #ed599c; }
  .pink-colors ul li:nth-child(8) p:before {
    content: "#ed599c"; }

.pink-colors ul li:nth-child(7) {
  background-color: #ef67a4; }
  .pink-colors ul li:nth-child(7) p:before {
    content: "#ef67a4"; }

.pink-colors ul li:nth-child(6) {
  background-color: #f074ac; }
  .pink-colors ul li:nth-child(6) p:before {
    content: "#f074ac"; }

.pink-colors ul li:nth-child(5) {
  background-color: #f282b5; }
  .pink-colors ul li:nth-child(5) p:before {
    content: "#f282b5"; }

.pink-colors ul li:nth-child(4) {
  background-color: #f390bd; }
  .pink-colors ul li:nth-child(4) p:before {
    content: "#f390bd"; }

.pink-colors ul li:nth-child(3) {
  background-color: #f59ec5; }
  .pink-colors ul li:nth-child(3) p:before {
    content: "#f59ec5"; }

.pink-colors ul li:nth-child(2) {
  background-color: #f6accd; }
  .pink-colors ul li:nth-child(2) p:before {
    content: "#f6accd"; }

.pink-colors ul li:nth-child(1) {
  background-color: #f8bad6; }
  .pink-colors ul li:nth-child(1) p:before {
    content: "#f8bad6"; }

/**====== Accent-pink-color css end ======**/
/**====== Accent-purple-color css start ======**/
.purple-colors ul li:nth-child(14) {
  background-color: #b014ff; }
  .purple-colors ul li:nth-child(14) p:before {
    content: "#b014ff"; }

.purple-colors ul li:nth-child(13) {
  background-color: #b524ff; }
  .purple-colors ul li:nth-child(13) p:before {
    content: "#b524ff"; }

.purple-colors ul li:nth-child(12) {
  background-color: #ba33ff; }
  .purple-colors ul li:nth-child(12) p:before {
    content: "#ba33ff"; }

.purple-colors ul li:nth-child(11) {
  background-color: #c042ff; }
  .purple-colors ul li:nth-child(11) p:before {
    content: "#c042ff"; }

.purple-colors ul li:nth-child(10) {
  background-color: #c552ff; }
  .purple-colors ul li:nth-child(10) p:before {
    content: "#c552ff"; }

.purple-colors ul li:nth-child(9) {
  background-color: #ca61ff; }
  .purple-colors ul li:nth-child(9) p:before {
    content: "#ca61ff"; }

.purple-colors ul li:nth-child(8) {
  background-color: #cf70ff; }
  .purple-colors ul li:nth-child(8) p:before {
    content: "#cf70ff"; }

.purple-colors ul li:nth-child(7) {
  background-color: #d47fff; }
  .purple-colors ul li:nth-child(7) p:before {
    content: "#d47fff"; }

.purple-colors ul li:nth-child(6) {
  background-color: #d98fff; }
  .purple-colors ul li:nth-child(6) p:before {
    content: "#d98fff"; }

.purple-colors ul li:nth-child(5) {
  background-color: #de9eff; }
  .purple-colors ul li:nth-child(5) p:before {
    content: "#de9eff"; }

.purple-colors ul li:nth-child(4) {
  background-color: #e4adff; }
  .purple-colors ul li:nth-child(4) p:before {
    content: "#e4adff"; }

.purple-colors ul li:nth-child(3) {
  background-color: #e9bdff; }
  .purple-colors ul li:nth-child(3) p:before {
    content: "#e9bdff"; }

.purple-colors ul li:nth-child(2) {
  background-color: #eeccff; }
  .purple-colors ul li:nth-child(2) p:before {
    content: "#eeccff"; }

.purple-colors ul li:nth-child(1) {
  background-color: #f3dbff; }
  .purple-colors ul li:nth-child(1) p:before {
    content: "#f3dbff"; }

/**====== Accent-purple-color css end ======**/
/**====== Deep-purple-color css start ======**/
.deep-purple-colors ul li:nth-child(14) {
  background-color: #6b05f9; }
  .deep-purple-colors ul li:nth-child(14) p:before {
    content: "#6b05f9"; }

.deep-purple-colors ul li:nth-child(13) {
  background-color: #7414fa; }
  .deep-purple-colors ul li:nth-child(13) p:before {
    content: "#7414fa"; }

.deep-purple-colors ul li:nth-child(12) {
  background-color: #7d23fa; }
  .deep-purple-colors ul li:nth-child(12) p:before {
    content: "#7d23fa"; }

.deep-purple-colors ul li:nth-child(11) {
  background-color: #8632fb; }
  .deep-purple-colors ul li:nth-child(11) p:before {
    content: "#8632fb"; }

.deep-purple-colors ul li:nth-child(10) {
  background-color: #8f41fb; }
  .deep-purple-colors ul li:nth-child(10) p:before {
    content: "#8f41fb"; }

.deep-purple-colors ul li:nth-child(9) {
  background-color: #9850fb; }
  .deep-purple-colors ul li:nth-child(9) p:before {
    content: "#9850fb"; }

.deep-purple-colors ul li:nth-child(8) {
  background-color: #a05ffc; }
  .deep-purple-colors ul li:nth-child(8) p:before {
    content: "#a05ffc"; }

.deep-purple-colors ul li:nth-child(7) {
  background-color: #a96efc; }
  .deep-purple-colors ul li:nth-child(7) p:before {
    content: "#a96efc"; }

.deep-purple-colors ul li:nth-child(6) {
  background-color: #b27cfc; }
  .deep-purple-colors ul li:nth-child(6) p:before {
    content: "#b27cfc"; }

.deep-purple-colors ul li:nth-child(5) {
  background-color: #bb8bfd; }
  .deep-purple-colors ul li:nth-child(5) p:before {
    content: "#bb8bfd"; }

.deep-purple-colors ul li:nth-child(4) {
  background-color: #c49afd; }
  .deep-purple-colors ul li:nth-child(4) p:before {
    content: "#c49afd"; }

.deep-purple-colors ul li:nth-child(3) {
  background-color: #cda9fd; }
  .deep-purple-colors ul li:nth-child(3) p:before {
    content: "#cda9fd"; }

.deep-purple-colors ul li:nth-child(2) {
  background-color: #d5b8fd; }
  .deep-purple-colors ul li:nth-child(2) p:before {
    content: "#d5b8fd"; }

.deep-purple-colors ul li:nth-child(1) {
  background-color: #dec7fe; }
  .deep-purple-colors ul li:nth-child(1) p:before {
    content: "#dec7fe"; }

/**====== Deep-purple-color css end ======**/
/**====== Indigo-color css start ======**/
.indigo-colors ul li:nth-child(14) {
  background-color: #415dfe; }
  .indigo-colors ul li:nth-child(14) p:before {
    content: "#415dfe"; }

.indigo-colors ul li:nth-child(13) {
  background-color: #4d68fe; }
  .indigo-colors ul li:nth-child(13) p:before {
    content: "#4d68fe"; }

.indigo-colors ul li:nth-child(12) {
  background-color: #5a72fe; }
  .indigo-colors ul li:nth-child(12) p:before {
    content: "#5a72fe"; }

.indigo-colors ul li:nth-child(11) {
  background-color: #677dfe; }
  .indigo-colors ul li:nth-child(11) p:before {
    content: "#677dfe"; }

.indigo-colors ul li:nth-child(10) {
  background-color: #7388fe; }
  .indigo-colors ul li:nth-child(10) p:before {
    content: "#7388fe"; }

.indigo-colors ul li:nth-child(9) {
  background-color: #8093fe; }
  .indigo-colors ul li:nth-child(9) p:before {
    content: "#8093fe"; }

.indigo-colors ul li:nth-child(8) {
  background-color: #8d9efe; }
  .indigo-colors ul li:nth-child(8) p:before {
    content: "#8d9efe"; }

.indigo-colors ul li:nth-child(7) {
  background-color: #9aa9ff; }
  .indigo-colors ul li:nth-child(7) p:before {
    content: "#9aa9ff"; }

.indigo-colors ul li:nth-child(6) {
  background-color: #a6b3ff; }
  .indigo-colors ul li:nth-child(6) p:before {
    content: "#a6b3ff"; }

.indigo-colors ul li:nth-child(5) {
  background-color: #b3beff; }
  .indigo-colors ul li:nth-child(5) p:before {
    content: "#b3beff"; }

.indigo-colors ul li:nth-child(4) {
  background-color: #c0c9ff; }
  .indigo-colors ul li:nth-child(4) p:before {
    content: "#c0c9ff"; }

.indigo-colors ul li:nth-child(3) {
  background-color: #ccd4ff; }
  .indigo-colors ul li:nth-child(3) p:before {
    content: "#ccd4ff"; }

.indigo-colors ul li:nth-child(2) {
  background-color: #d9dfff; }
  .indigo-colors ul li:nth-child(2) p:before {
    content: "#d9dfff"; }

.indigo-colors ul li:nth-child(1) {
  background-color: #e6e9ff; }
  .indigo-colors ul li:nth-child(1) p:before {
    content: "#e6e9ff"; }

/**====== Indigo-color css end ======**/
/**====== Blue-accent-color css start ======**/
.blue-colors ul li:nth-child(14) {
  background-color: #3a6eff; }
  .blue-colors ul li:nth-child(14) p:before {
    content: "#3a6eff"; }

.blue-colors ul li:nth-child(13) {
  background-color: #4778ff; }
  .blue-colors ul li:nth-child(13) p:before {
    content: "#4778ff"; }

.blue-colors ul li:nth-child(12) {
  background-color: #5381ff; }
  .blue-colors ul li:nth-child(12) p:before {
    content: "#5381ff"; }

.blue-colors ul li:nth-child(11) {
  background-color: #608aff; }
  .blue-colors ul li:nth-child(11) p:before {
    content: "#608aff"; }

.blue-colors ul li:nth-child(10) {
  background-color: #6d94ff; }
  .blue-colors ul li:nth-child(10) p:before {
    content: "#6d94ff"; }

.blue-colors ul li:nth-child(9) {
  background-color: #7a9dff; }
  .blue-colors ul li:nth-child(9) p:before {
    content: "#7a9dff"; }

.blue-colors ul li:nth-child(8) {
  background-color: #86a6ff; }
  .blue-colors ul li:nth-child(8) p:before {
    content: "#86a6ff"; }

.blue-colors ul li:nth-child(7) {
  background-color: #93b0ff; }
  .blue-colors ul li:nth-child(7) p:before {
    content: "#93b0ff"; }

.blue-colors ul li:nth-child(6) {
  background-color: #a0b9ff; }
  .blue-colors ul li:nth-child(6) p:before {
    content: "#a0b9ff"; }

.blue-colors ul li:nth-child(5) {
  background-color: #adc3ff; }
  .blue-colors ul li:nth-child(5) p:before {
    content: "#adc3ff"; }

.blue-colors ul li:nth-child(4) {
  background-color: #b9ccff; }
  .blue-colors ul li:nth-child(4) p:before {
    content: "#b9ccff"; }

.blue-colors ul li:nth-child(3) {
  background-color: #c6d5ff; }
  .blue-colors ul li:nth-child(3) p:before {
    content: "#c6d5ff"; }

.blue-colors ul li:nth-child(2) {
  background-color: #d3dfff; }
  .blue-colors ul li:nth-child(2) p:before {
    content: "#d3dfff"; }

.blue-colors ul li:nth-child(1) {
  background-color: #e0e8ff; }
  .blue-colors ul li:nth-child(1) p:before {
    content: "#e0e8ff"; }

/**====== Blue-accent-color css end ======**/
/**====== Light-blue-color css start ======**/
.light-blue-colors ul li:nth-child(14) {
  background-color: #059cf9; }
  .light-blue-colors ul li:nth-child(14) p:before {
    content: "#059cf9"; }

.light-blue-colors ul li:nth-child(13) {
  background-color: #14a2fa; }
  .light-blue-colors ul li:nth-child(13) p:before {
    content: "#14a2fa"; }

.light-blue-colors ul li:nth-child(12) {
  background-color: #23a8fa; }
  .light-blue-colors ul li:nth-child(12) p:before {
    content: "#23a8fa"; }

.light-blue-colors ul li:nth-child(11) {
  background-color: #32aefb; }
  .light-blue-colors ul li:nth-child(11) p:before {
    content: "#32aefb"; }

.light-blue-colors ul li:nth-child(10) {
  background-color: #41b4fb; }
  .light-blue-colors ul li:nth-child(10) p:before {
    content: "#41b4fb"; }

.light-blue-colors ul li:nth-child(9) {
  background-color: #50bafb; }
  .light-blue-colors ul li:nth-child(9) p:before {
    content: "#50bafb"; }

.light-blue-colors ul li:nth-child(8) {
  background-color: #5fc0fc; }
  .light-blue-colors ul li:nth-child(8) p:before {
    content: "#5fc0fc"; }

.light-blue-colors ul li:nth-child(7) {
  background-color: #6ec6fc; }
  .light-blue-colors ul li:nth-child(7) p:before {
    content: "#6ec6fc"; }

.light-blue-colors ul li:nth-child(6) {
  background-color: #7cccfc; }
  .light-blue-colors ul li:nth-child(6) p:before {
    content: "#7cccfc"; }

.light-blue-colors ul li:nth-child(5) {
  background-color: #8bd2fd; }
  .light-blue-colors ul li:nth-child(5) p:before {
    content: "#8bd2fd"; }

.light-blue-colors ul li:nth-child(4) {
  background-color: #9ad7fd; }
  .light-blue-colors ul li:nth-child(4) p:before {
    content: "#9ad7fd"; }

.light-blue-colors ul li:nth-child(3) {
  background-color: #a9ddfd; }
  .light-blue-colors ul li:nth-child(3) p:before {
    content: "#a9ddfd"; }

.light-blue-colors ul li:nth-child(2) {
  background-color: #b8e3fd; }
  .light-blue-colors ul li:nth-child(2) p:before {
    content: "#b8e3fd"; }

.light-blue-colors ul li:nth-child(1) {
  background-color: #c7e9fe; }
  .light-blue-colors ul li:nth-child(1) p:before {
    content: "#c7e9fe"; }

/**====== Light-blue-color css end ======**/
/**====== Cyan-color css start ======**/
.cyan-colors ul li:nth-child(14) {
  background-color: #05c6e3; }
  .cyan-colors ul li:nth-child(14) p:before {
    content: "#05c6e3"; }

.cyan-colors ul li:nth-child(13) {
  background-color: #06d3f2; }
  .cyan-colors ul li:nth-child(13) p:before {
    content: "#06d3f2"; }

.cyan-colors ul li:nth-child(12) {
  background-color: #0edbf9; }
  .cyan-colors ul li:nth-child(12) p:before {
    content: "#0edbf9"; }

.cyan-colors ul li:nth-child(11) {
  background-color: #1dddfa; }
  .cyan-colors ul li:nth-child(11) p:before {
    content: "#1dddfa"; }

.cyan-colors ul li:nth-child(10) {
  background-color: #2bdffa; }
  .cyan-colors ul li:nth-child(10) p:before {
    content: "#2bdffa"; }

.cyan-colors ul li:nth-child(9) {
  background-color: #3ae1fa; }
  .cyan-colors ul li:nth-child(9) p:before {
    content: "#3ae1fa"; }

.cyan-colors ul li:nth-child(8) {
  background-color: #49e4fb; }
  .cyan-colors ul li:nth-child(8) p:before {
    content: "#49e4fb"; }

.cyan-colors ul li:nth-child(7) {
  background-color: #58e6fb; }
  .cyan-colors ul li:nth-child(7) p:before {
    content: "#58e6fb"; }

.cyan-colors ul li:nth-child(6) {
  background-color: #67e8fb; }
  .cyan-colors ul li:nth-child(6) p:before {
    content: "#67e8fb"; }

.cyan-colors ul li:nth-child(5) {
  background-color: #76eafc; }
  .cyan-colors ul li:nth-child(5) p:before {
    content: "#76eafc"; }

.cyan-colors ul li:nth-child(4) {
  background-color: #85edfc; }
  .cyan-colors ul li:nth-child(4) p:before {
    content: "#85edfc"; }

.cyan-colors ul li:nth-child(3) {
  background-color: #94effc; }
  .cyan-colors ul li:nth-child(3) p:before {
    content: "#94effc"; }

.cyan-colors ul li:nth-child(2) {
  background-color: #a3f1fd; }
  .cyan-colors ul li:nth-child(2) p:before {
    content: "#a3f1fd"; }

.cyan-colors ul li:nth-child(1) {
  background-color: #b2f3fd; }
  .cyan-colors ul li:nth-child(1) p:before {
    content: "#b2f3fd"; }

/**====== Cyan-color css start ======**/
/**====== Teal-color css start ======**/
.teal-colors ul li:nth-child(14) {
  background-color: #05cfb3; }
  .teal-colors ul li:nth-child(14) p:before {
    content: "#05cfb3"; }

.teal-colors ul li:nth-child(13) {
  background-color: #06dec0; }
  .teal-colors ul li:nth-child(13) p:before {
    content: "#06dec0"; }

.teal-colors ul li:nth-child(12) {
  background-color: #06edcd; }
  .teal-colors ul li:nth-child(12) p:before {
    content: "#06edcd"; }

.teal-colors ul li:nth-child(11) {
  background-color: #0af9d7; }
  .teal-colors ul li:nth-child(11) p:before {
    content: "#0af9d7"; }

.teal-colors ul li:nth-child(10) {
  background-color: #19f9da; }
  .teal-colors ul li:nth-child(10) p:before {
    content: "#19f9da"; }

.teal-colors ul li:nth-child(9) {
  background-color: #27f9dc; }
  .teal-colors ul li:nth-child(9) p:before {
    content: "#27f9dc"; }

.teal-colors ul li:nth-child(8) {
  background-color: #36fadf; }
  .teal-colors ul li:nth-child(8) p:before {
    content: "#36fadf"; }

.teal-colors ul li:nth-child(7) {
  background-color: #45fae1; }
  .teal-colors ul li:nth-child(7) p:before {
    content: "#45fae1"; }

.teal-colors ul li:nth-child(6) {
  background-color: #54fbe3; }
  .teal-colors ul li:nth-child(6) p:before {
    content: "#54fbe3"; }

.teal-colors ul li:nth-child(5) {
  background-color: #63fbe6; }
  .teal-colors ul li:nth-child(5) p:before {
    content: "#63fbe6"; }

.teal-colors ul li:nth-child(4) {
  background-color: #72fbe8; }
  .teal-colors ul li:nth-child(4) p:before {
    content: "#72fbe8"; }

.teal-colors ul li:nth-child(3) {
  background-color: #81fceb; }
  .teal-colors ul li:nth-child(3) p:before {
    content: "#81fceb"; }

.teal-colors ul li:nth-child(2) {
  background-color: #90fced; }
  .teal-colors ul li:nth-child(2) p:before {
    content: "#90fced"; }

.teal-colors ul li:nth-child(1) {
  background-color: #9ffcef; }
  .teal-colors ul li:nth-child(1) p:before {
    content: "#9ffcef"; }

/**====== Teal-color css end ======**/
/**====== Green-color css start ======**/
.green-colors ul li:nth-child(14) {
  background-color: #05d85c; }
  .green-colors ul li:nth-child(14) p:before {
    content: "#05d85c"; }

.green-colors ul li:nth-child(13) {
  background-color: #06e763; }
  .green-colors ul li:nth-child(13) p:before {
    content: "#06e763"; }

.green-colors ul li:nth-child(12) {
  background-color: #06f669; }
  .green-colors ul li:nth-child(12) p:before {
    content: "#06f669"; }

.green-colors ul li:nth-child(11) {
  background-color: #12f972; }
  .green-colors ul li:nth-child(11) p:before {
    content: "#12f972"; }

.green-colors ul li:nth-child(10) {
  background-color: #21f97a; }
  .green-colors ul li:nth-child(10) p:before {
    content: "#21f97a"; }

.green-colors ul li:nth-child(9) {
  background-color: #30fa83; }
  .green-colors ul li:nth-child(9) p:before {
    content: "#30fa83"; }

.green-colors ul li:nth-child(8) {
  background-color: #3ffa8c; }
  .green-colors ul li:nth-child(8) p:before {
    content: "#3ffa8c"; }

.green-colors ul li:nth-child(7) {
  background-color: #4efb95; }
  .green-colors ul li:nth-child(7) p:before {
    content: "#4efb95"; }

.green-colors ul li:nth-child(6) {
  background-color: #5dfb9e; }
  .green-colors ul li:nth-child(6) p:before {
    content: "#5dfb9e"; }

.green-colors ul li:nth-child(5) {
  background-color: #6cfba7; }
  .green-colors ul li:nth-child(5) p:before {
    content: "#6cfba7"; }

.green-colors ul li:nth-child(4) {
  background-color: #7bfcb0; }
  .green-colors ul li:nth-child(4) p:before {
    content: "#7bfcb0"; }

.green-colors ul li:nth-child(3) {
  background-color: #8afcb9; }
  .green-colors ul li:nth-child(3) p:before {
    content: "#8afcb9"; }

.green-colors ul li:nth-child(2) {
  background-color: #98fcc2; }
  .green-colors ul li:nth-child(2) p:before {
    content: "#98fcc2"; }

.green-colors ul li:nth-child(1) {
  background-color: #a7fdcb; }
  .green-colors ul li:nth-child(1) p:before {
    content: "#a7fdcb"; }

/**====== Green-color css end ======**/
/**====== Light-Green-color css start ======**/
.light-green-colors ul li:nth-child(14) {
  background-color: #6fe423; }
  .light-green-colors ul li:nth-child(14) p:before {
    content: "#6fe423"; }

.light-green-colors ul li:nth-child(13) {
  background-color: #78e631; }
  .light-green-colors ul li:nth-child(13) p:before {
    content: "#78e631"; }

.light-green-colors ul li:nth-child(12) {
  background-color: #81e73e; }
  .light-green-colors ul li:nth-child(12) p:before {
    content: "#81e73e"; }

.light-green-colors ul li:nth-child(11) {
  background-color: #8ae94c; }
  .light-green-colors ul li:nth-child(11) p:before {
    content: "#8ae94c"; }

.light-green-colors ul li:nth-child(10) {
  background-color: #93eb5a; }
  .light-green-colors ul li:nth-child(10) p:before {
    content: "#93eb5a"; }

.light-green-colors ul li:nth-child(9) {
  background-color: #9bec67; }
  .light-green-colors ul li:nth-child(9) p:before {
    content: "#9bec67"; }

.light-green-colors ul li:nth-child(8) {
  background-color: #a4ee75; }
  .light-green-colors ul li:nth-child(8) p:before {
    content: "#a4ee75"; }

.light-green-colors ul li:nth-child(7) {
  background-color: #adf083; }
  .light-green-colors ul li:nth-child(7) p:before {
    content: "#adf083"; }

.light-green-colors ul li:nth-child(6) {
  background-color: #b6f190; }
  .light-green-colors ul li:nth-child(6) p:before {
    content: "#b6f190"; }

.light-green-colors ul li:nth-child(5) {
  background-color: #bff39e; }
  .light-green-colors ul li:nth-child(5) p:before {
    content: "#bff39e"; }

.light-green-colors ul li:nth-child(4) {
  background-color: #c8f5ac; }
  .light-green-colors ul li:nth-child(4) p:before {
    content: "#c8f5ac"; }

.light-green-colors ul li:nth-child(3) {
  background-color: #d1f6b9; }
  .light-green-colors ul li:nth-child(3) p:before {
    content: "#d1f6b9"; }

.light-green-colors ul li:nth-child(2) {
  background-color: #daf8c7; }
  .light-green-colors ul li:nth-child(2) p:before {
    content: "#daf8c7"; }

.light-green-colors ul li:nth-child(1) {
  background-color: #e3fad4; }
  .light-green-colors ul li:nth-child(1) p:before {
    content: "#e3fad4"; }

/**====== Light-Green-color css end ======**/
/**====== Lime-color css start ======**/
.lime-colors ul li:nth-child(14) {
  background-color: #baf905; }
  .lime-colors ul li:nth-child(14) p:before {
    content: "#baf905"; }

.lime-colors ul li:nth-child(13) {
  background-color: #bffa14; }
  .lime-colors ul li:nth-child(13) p:before {
    content: "#bffa14"; }

.lime-colors ul li:nth-child(12) {
  background-color: #c3fa23; }
  .lime-colors ul li:nth-child(12) p:before {
    content: "#c3fa23"; }

.lime-colors ul li:nth-child(11) {
  background-color: #c7fb32; }
  .lime-colors ul li:nth-child(11) p:before {
    content: "#c7fb32"; }

.lime-colors ul li:nth-child(10) {
  background-color: #cbfb41; }
  .lime-colors ul li:nth-child(10) p:before {
    content: "#cbfb41"; }

.lime-colors ul li:nth-child(9) {
  background-color: #cffb50; }
  .lime-colors ul li:nth-child(9) p:before {
    content: "#cffb50"; }

.lime-colors ul li:nth-child(8) {
  background-color: #d3fc5f; }
  .lime-colors ul li:nth-child(8) p:before {
    content: "#d3fc5f"; }

.lime-colors ul li:nth-child(7) {
  background-color: #d7fc6e; }
  .lime-colors ul li:nth-child(7) p:before {
    content: "#d7fc6e"; }

.lime-colors ul li:nth-child(6) {
  background-color: #dbfc7c; }
  .lime-colors ul li:nth-child(6) p:before {
    content: "#dbfc7c"; }

.lime-colors ul li:nth-child(5) {
  background-color: #dffd8b; }
  .lime-colors ul li:nth-child(5) p:before {
    content: "#dffd8b"; }

.lime-colors ul li:nth-child(4) {
  background-color: #e3fd9a; }
  .lime-colors ul li:nth-child(4) p:before {
    content: "#e3fd9a"; }

.lime-colors ul li:nth-child(3) {
  background-color: #e8fda9; }
  .lime-colors ul li:nth-child(3) p:before {
    content: "#e8fda9"; }

.lime-colors ul li:nth-child(2) {
  background-color: #ecfdb8; }
  .lime-colors ul li:nth-child(2) p:before {
    content: "#ecfdb8"; }

.lime-colors ul li:nth-child(1) {
  background-color: #f0fec7; }
  .lime-colors ul li:nth-child(1) p:before {
    content: "#f0fec7"; }

/**====== Lime-color css end ======**/
/**====== Yellow-color css start ======**/
.yellow-colors ul li:nth-child(14) {
  background-color: #ffd812; }
  .yellow-colors ul li:nth-child(14) p:before {
    content: "#ffd812"; }

.yellow-colors ul li:nth-child(13) {
  background-color: #ffda1f; }
  .yellow-colors ul li:nth-child(13) p:before {
    content: "#ffda1f"; }

.yellow-colors ul li:nth-child(12) {
  background-color: #ffdc2b; }
  .yellow-colors ul li:nth-child(12) p:before {
    content: "#ffdc2b"; }

.yellow-colors ul li:nth-child(11) {
  background-color: #ffde38; }
  .yellow-colors ul li:nth-child(11) p:before {
    content: "#ffde38"; }

.yellow-colors ul li:nth-child(10) {
  background-color: #ffe045; }
  .yellow-colors ul li:nth-child(10) p:before {
    content: "#ffe045"; }

.yellow-colors ul li:nth-child(9) {
  background-color: #ffe352; }
  .yellow-colors ul li:nth-child(9) p:before {
    content: "#ffe352"; }

.yellow-colors ul li:nth-child(8) {
  background-color: #ffe55e; }
  .yellow-colors ul li:nth-child(8) p:before {
    content: "#ffe55e"; }

.yellow-colors ul li:nth-child(7) {
  background-color: #ffe76b; }
  .yellow-colors ul li:nth-child(7) p:before {
    content: "#ffe76b"; }

.yellow-colors ul li:nth-child(6) {
  background-color: #ffe978; }
  .yellow-colors ul li:nth-child(6) p:before {
    content: "#ffe978"; }

.yellow-colors ul li:nth-child(5) {
  background-color: #ffeb85; }
  .yellow-colors ul li:nth-child(5) p:before {
    content: "#ffeb85"; }

.yellow-colors ul li:nth-child(4) {
  background-color: #ffed91; }
  .yellow-colors ul li:nth-child(4) p:before {
    content: "#ffed91"; }

.yellow-colors ul li:nth-child(3) {
  background-color: #ffef9e; }
  .yellow-colors ul li:nth-child(3) p:before {
    content: "#ffef9e"; }

.yellow-colors ul li:nth-child(2) {
  background-color: #fff1ab; }
  .yellow-colors ul li:nth-child(2) p:before {
    content: "#fff1ab"; }

.yellow-colors ul li:nth-child(1) {
  background-color: #fff3b8; }
  .yellow-colors ul li:nth-child(1) p:before {
    content: "#fff3b8"; }

/**====== Yellow-color css end ======**/
/**====== Amber-color css start ======**/
.amber-colors ul li:nth-child(14) {
  background-color: #ffb012; }
  .amber-colors ul li:nth-child(14) p:before {
    content: "#ffb012"; }

.amber-colors ul li:nth-child(13) {
  background-color: #ffb41f; }
  .amber-colors ul li:nth-child(13) p:before {
    content: "#ffb41f"; }

.amber-colors ul li:nth-child(12) {
  background-color: #ffb92b; }
  .amber-colors ul li:nth-child(12) p:before {
    content: "#ffb92b"; }

.amber-colors ul li:nth-child(11) {
  background-color: #ffbd38; }
  .amber-colors ul li:nth-child(11) p:before {
    content: "#ffbd38"; }

.amber-colors ul li:nth-child(10) {
  background-color: #ffc145; }
  .amber-colors ul li:nth-child(10) p:before {
    content: "#ffc145"; }

.amber-colors ul li:nth-child(9) {
  background-color: #ffc552; }
  .amber-colors ul li:nth-child(9) p:before {
    content: "#ffc552"; }

.amber-colors ul li:nth-child(8) {
  background-color: #ffca5e; }
  .amber-colors ul li:nth-child(8) p:before {
    content: "#ffca5e"; }

.amber-colors ul li:nth-child(7) {
  background-color: #ffce6b; }
  .amber-colors ul li:nth-child(7) p:before {
    content: "#ffce6b"; }

.amber-colors ul li:nth-child(6) {
  background-color: #ffd278; }
  .amber-colors ul li:nth-child(6) p:before {
    content: "#ffd278"; }

.amber-colors ul li:nth-child(5) {
  background-color: #ffd685; }
  .amber-colors ul li:nth-child(5) p:before {
    content: "#ffd685"; }

.amber-colors ul li:nth-child(4) {
  background-color: #ffdb91; }
  .amber-colors ul li:nth-child(4) p:before {
    content: "#ffdb91"; }

.amber-colors ul li:nth-child(3) {
  background-color: #ffdf9e; }
  .amber-colors ul li:nth-child(3) p:before {
    content: "#ffdf9e"; }

.amber-colors ul li:nth-child(2) {
  background-color: #ffe3ab; }
  .amber-colors ul li:nth-child(2) p:before {
    content: "#ffe3ab"; }

.amber-colors ul li:nth-child(1) {
  background-color: #ffe7b8; }
  .amber-colors ul li:nth-child(1) p:before {
    content: "#ffe7b8"; }

/**====== Amber-color css end ======**/
/**====== Orange-color css start ======**/
.orange-colors ul li:nth-child(14) {
  background-color: #ff7814; }
  .orange-colors ul li:nth-child(14) p:before {
    content: "#ff7814"; }

.orange-colors ul li:nth-child(13) {
  background-color: #ff8124; }
  .orange-colors ul li:nth-child(13) p:before {
    content: "#ff8124"; }

.orange-colors ul li:nth-child(12) {
  background-color: #ff8933; }
  .orange-colors ul li:nth-child(12) p:before {
    content: "#ff8933"; }

.orange-colors ul li:nth-child(11) {
  background-color: #ff9242; }
  .orange-colors ul li:nth-child(11) p:before {
    content: "#ff9242"; }

.orange-colors ul li:nth-child(10) {
  background-color: #ff9b52; }
  .orange-colors ul li:nth-child(10) p:before {
    content: "#ff9b52"; }

.orange-colors ul li:nth-child(9) {
  background-color: #ffa461; }
  .orange-colors ul li:nth-child(9) p:before {
    content: "#ffa461"; }

.orange-colors ul li:nth-child(8) {
  background-color: #ffad70; }
  .orange-colors ul li:nth-child(8) p:before {
    content: "#ffad70"; }

.orange-colors ul li:nth-child(7) {
  background-color: #ffb67f; }
  .orange-colors ul li:nth-child(7) p:before {
    content: "#ffb67f"; }

.orange-colors ul li:nth-child(6) {
  background-color: #ffbe8f; }
  .orange-colors ul li:nth-child(6) p:before {
    content: "#ffbe8f"; }

.orange-colors ul li:nth-child(5) {
  background-color: #ffc79e; }
  .orange-colors ul li:nth-child(5) p:before {
    content: "#ffc79e"; }

.orange-colors ul li:nth-child(4) {
  background-color: #ffd0ad; }
  .orange-colors ul li:nth-child(4) p:before {
    content: "#ffd0ad"; }

.orange-colors ul li:nth-child(3) {
  background-color: #ffd9bd; }
  .orange-colors ul li:nth-child(3) p:before {
    content: "#ffd9bd"; }

.orange-colors ul li:nth-child(2) {
  background-color: #ffe2cc; }
  .orange-colors ul li:nth-child(2) p:before {
    content: "#ffe2cc"; }

.orange-colors ul li:nth-child(1) {
  background-color: #ffeadb; }
  .orange-colors ul li:nth-child(1) p:before {
    content: "#ffeadb"; }

/**====== Orange-color css start ======**/
/**====== Deep-Orange-color css start ======**/
.deep-orange-colors ul li:nth-child(14) {
  background-color: #ec3305; }
  .deep-orange-colors ul li:nth-child(14) p:before {
    content: "#ec3305"; }

.deep-orange-colors ul li:nth-child(13) {
  background-color: #f93707; }
  .deep-orange-colors ul li:nth-child(13) p:before {
    content: "#f93707"; }

.deep-orange-colors ul li:nth-child(12) {
  background-color: #fa4316; }
  .deep-orange-colors ul li:nth-child(12) p:before {
    content: "#fa4316"; }

.deep-orange-colors ul li:nth-child(11) {
  background-color: #fa5025; }
  .deep-orange-colors ul li:nth-child(11) p:before {
    content: "#fa5025"; }

.deep-orange-colors ul li:nth-child(10) {
  background-color: #fa5c34; }
  .deep-orange-colors ul li:nth-child(10) p:before {
    content: "#fa5c34"; }

.deep-orange-colors ul li:nth-child(9) {
  background-color: #fb6843; }
  .deep-orange-colors ul li:nth-child(9) p:before {
    content: "#fb6843"; }

.deep-orange-colors ul li:nth-child(8) {
  background-color: #fb7452; }
  .deep-orange-colors ul li:nth-child(8) p:before {
    content: "#fb7452"; }

.deep-orange-colors ul li:nth-child(7) {
  background-color: #fb8061; }
  .deep-orange-colors ul li:nth-child(7) p:before {
    content: "#fb8061"; }

.deep-orange-colors ul li:nth-child(6) {
  background-color: #fc8c70; }
  .deep-orange-colors ul li:nth-child(6) p:before {
    content: "#fc8c70"; }

.deep-orange-colors ul li:nth-child(5) {
  background-color: #fc987f; }
  .deep-orange-colors ul li:nth-child(5) p:before {
    content: "#fc987f"; }

.deep-orange-colors ul li:nth-child(4) {
  background-color: #fca48e; }
  .deep-orange-colors ul li:nth-child(4) p:before {
    content: "#fca48e"; }

.deep-orange-colors ul li:nth-child(3) {
  background-color: #fdb09d; }
  .deep-orange-colors ul li:nth-child(3) p:before {
    content: "#fdb09d"; }

.deep-orange-colors ul li:nth-child(2) {
  background-color: #fdbcac; }
  .deep-orange-colors ul li:nth-child(2) p:before {
    content: "#fdbcac"; }

.deep-orange-colors ul li:nth-child(1) {
  background-color: #fdc8bb; }
  .deep-orange-colors ul li:nth-child(1) p:before {
    content: "#fdc8bb"; }

/**====== Deep-Orange-color css end ======**/
/**====== Brown-color css start ======**/
.brown-colors ul li:nth-child(14) {
  background-color: #513631; }
  .brown-colors ul li:nth-child(14) p:before {
    content: "#513631"; }

.brown-colors ul li:nth-child(13) {
  background-color: #61403a; }
  .brown-colors ul li:nth-child(13) p:before {
    content: "#61403a"; }

.brown-colors ul li:nth-child(12) {
  background-color: #714b44; }
  .brown-colors ul li:nth-child(12) p:before {
    content: "#714b44"; }

.brown-colors ul li:nth-child(11) {
  background-color: #81554d; }
  .brown-colors ul li:nth-child(11) p:before {
    content: "#81554d"; }

.brown-colors ul li:nth-child(10) {
  background-color: #916057; }
  .brown-colors ul li:nth-child(10) p:before {
    content: "#916057"; }

.brown-colors ul li:nth-child(9) {
  background-color: #a06b61; }
  .brown-colors ul li:nth-child(9) p:before {
    content: "#a06b61"; }

.brown-colors ul li:nth-child(8) {
  background-color: #aa7a71; }
  .brown-colors ul li:nth-child(8) p:before {
    content: "#aa7a71"; }

.brown-colors ul li:nth-child(7) {
  background-color: #b38981; }
  .brown-colors ul li:nth-child(7) p:before {
    content: "#b38981"; }

.brown-colors ul li:nth-child(6) {
  background-color: #bd9791; }
  .brown-colors ul li:nth-child(6) p:before {
    content: "#bd9791"; }

.brown-colors ul li:nth-child(5) {
  background-color: #c6a6a1; }
  .brown-colors ul li:nth-child(5) p:before {
    content: "#c6a6a1"; }

.brown-colors ul li:nth-child(4) {
  background-color: #d0b5b1; }
  .brown-colors ul li:nth-child(4) p:before {
    content: "#d0b5b1"; }

.brown-colors ul li:nth-child(3) {
  background-color: #dac4c1; }
  .brown-colors ul li:nth-child(3) p:before {
    content: "#dac4c1"; }

.brown-colors ul li:nth-child(2) {
  background-color: #e3d3d0; }
  .brown-colors ul li:nth-child(2) p:before {
    content: "#e3d3d0"; }

.brown-colors ul li:nth-child(1) {
  background-color: #ede2e0; }
  .brown-colors ul li:nth-child(1) p:before {
    content: "#ede2e0"; }

/**====== Brown-color css end ======**/
/**====== Grey-color css start ======**/
.grey-colors ul li:nth-child(14) {
  background-color: #323232; }
  .grey-colors ul li:nth-child(14) p:before {
    content: "#323232"; }

.grey-colors ul li:nth-child(13) {
  background-color: #3f3e3e; }
  .grey-colors ul li:nth-child(13) p:before {
    content: "#3f3e3e"; }

.grey-colors ul li:nth-child(12) {
  background-color: #4b4b4b; }
  .grey-colors ul li:nth-child(12) p:before {
    content: "#4b4b4b"; }

.grey-colors ul li:nth-child(11) {
  background-color: #585858; }
  .grey-colors ul li:nth-child(11) p:before {
    content: "#585858"; }

.grey-colors ul li:nth-child(10) {
  background-color: #656565; }
  .grey-colors ul li:nth-child(10) p:before {
    content: "#656565"; }

.grey-colors ul li:nth-child(9) {
  background-color: #727171; }
  .grey-colors ul li:nth-child(9) p:before {
    content: "#727171"; }

.grey-colors ul li:nth-child(8) {
  background-color: #7e7e7e; }
  .grey-colors ul li:nth-child(8) p:before {
    content: "#7e7e7e"; }

.grey-colors ul li:nth-child(7) {
  background-color: #8b8b8b; }
  .grey-colors ul li:nth-child(7) p:before {
    content: "#8b8b8b"; }

.grey-colors ul li:nth-child(6) {
  background-color: #989898; }
  .grey-colors ul li:nth-child(6) p:before {
    content: "#989898"; }

.grey-colors ul li:nth-child(5) {
  background-color: #a5a4a4; }
  .grey-colors ul li:nth-child(5) p:before {
    content: "#a5a4a4"; }

.grey-colors ul li:nth-child(4) {
  background-color: #b1b1b1; }
  .grey-colors ul li:nth-child(4) p:before {
    content: "#b1b1b1"; }

.grey-colors ul li:nth-child(3) {
  background-color: #bebebe; }
  .grey-colors ul li:nth-child(3) p:before {
    content: "#bebebe"; }

.grey-colors ul li:nth-child(2) {
  background-color: #cbcbcb; }
  .grey-colors ul li:nth-child(2) p:before {
    content: "#cbcbcb"; }

.grey-colors ul li:nth-child(1) {
  background-color: #d8d7d7; }
  .grey-colors ul li:nth-child(1) p:before {
    content: "#d8d7d7"; }

/**====== Grey-color css end ======**/
/**====== Blue-color css start ======**/
.blue-grey-colors ul li:nth-child(14) {
  background-color: #35444a; }
  .blue-grey-colors ul li:nth-child(14) p:before {
    content: "#35444a"; }

.blue-grey-colors ul li:nth-child(13) {
  background-color: #3f5159; }
  .blue-grey-colors ul li:nth-child(13) p:before {
    content: "#3f5159"; }

.blue-grey-colors ul li:nth-child(12) {
  background-color: #4a5f68; }
  .blue-grey-colors ul li:nth-child(12) p:before {
    content: "#4a5f68"; }

.blue-grey-colors ul li:nth-child(11) {
  background-color: #546d77; }
  .blue-grey-colors ul li:nth-child(11) p:before {
    content: "#546d77"; }

.blue-grey-colors ul li:nth-child(10) {
  background-color: #5f7a85; }
  .blue-grey-colors ul li:nth-child(10) p:before {
    content: "#5f7a85"; }

.blue-grey-colors ul li:nth-child(9) {
  background-color: #6a8894; }
  .blue-grey-colors ul li:nth-child(9) p:before {
    content: "#6a8894"; }

.blue-grey-colors ul li:nth-child(8) {
  background-color: #78949f; }
  .blue-grey-colors ul li:nth-child(8) p:before {
    content: "#78949f"; }

.blue-grey-colors ul li:nth-child(7) {
  background-color: #87a0aa; }
  .blue-grey-colors ul li:nth-child(7) p:before {
    content: "#87a0aa"; }

.blue-grey-colors ul li:nth-child(6) {
  background-color: #96abb4; }
  .blue-grey-colors ul li:nth-child(6) p:before {
    content: "#96abb4"; }

.blue-grey-colors ul li:nth-child(5) {
  background-color: #a5b7bf; }
  .blue-grey-colors ul li:nth-child(5) p:before {
    content: "#a5b7bf"; }

.blue-grey-colors ul li:nth-child(4) {
  background-color: #b4c3ca; }
  .blue-grey-colors ul li:nth-child(4) p:before {
    content: "#b4c3ca"; }

.blue-grey-colors ul li:nth-child(3) {
  background-color: #c3cfd4; }
  .blue-grey-colors ul li:nth-child(3) p:before {
    content: "#c3cfd4"; }

.blue-grey-colors ul li:nth-child(2) {
  background-color: #d2dbdf; }
  .blue-grey-colors ul li:nth-child(2) p:before {
    content: "#d2dbdf"; }

.blue-grey-colors ul li:nth-child(1) {
  background-color: #e1e7e9; }
  .blue-grey-colors ul li:nth-child(1) p:before {
    content: "#e1e7e9"; }

/**====== Blue-color css end ======**/
/**====== Primary-color css start ======**/
.primary-colorr ul li:nth-child(14) {
  background-color: #1cc9a7; }
  .primary-colorr ul li:nth-child(14) p:before {
    content: "#1cc9a7"; }

.primary-colorr ul li:nth-child(13) {
  background-color: #1ed7b2; }
  .primary-colorr ul li:nth-child(13) p:before {
    content: "#1ed7b2"; }

.primary-colorr ul li:nth-child(12) {
  background-color: #23e1bb; }
  .primary-colorr ul li:nth-child(12) p:before {
    content: "#23e1bb"; }

.primary-colorr ul li:nth-child(11) {
  background-color: #31e2bf; }
  .primary-colorr ul li:nth-child(11) p:before {
    content: "#31e2bf"; }

.primary-colorr ul li:nth-child(10) {
  background-color: #3ee4c4; }
  .primary-colorr ul li:nth-child(10) p:before {
    content: "#3ee4c4"; }

.primary-colorr ul li:nth-child(9) {
  background-color: #4ce6c8; }
  .primary-colorr ul li:nth-child(9) p:before {
    content: "#4ce6c8"; }

.primary-colorr ul li:nth-child(8) {
  background-color: #59e8cc; }
  .primary-colorr ul li:nth-child(8) p:before {
    content: "#59e8cc"; }

.primary-colorr ul li:nth-child(7) {
  background-color: #66ead0; }
  .primary-colorr ul li:nth-child(7) p:before {
    content: "#66ead0"; }

.primary-colorr ul li:nth-child(6) {
  background-color: #74ecd4; }
  .primary-colorr ul li:nth-child(6) p:before {
    content: "#74ecd4"; }

.primary-colorr ul li:nth-child(5) {
  background-color: #81eed8; }
  .primary-colorr ul li:nth-child(5) p:before {
    content: "#81eed8"; }

.primary-colorr ul li:nth-child(4) {
  background-color: #8fefdc; }
  .primary-colorr ul li:nth-child(4) p:before {
    content: "#8fefdc"; }

.primary-colorr ul li:nth-child(3) {
  background-color: #9cf1e1; }
  .primary-colorr ul li:nth-child(3) p:before {
    content: "#9cf1e1"; }

.primary-colorr ul li:nth-child(2) {
  background-color: #aaf3e5; }
  .primary-colorr ul li:nth-child(2) p:before {
    content: "#aaf3e5"; }

.primary-colorr ul li:nth-child(1) {
  background-color: #b7f5e9; }
  .primary-colorr ul li:nth-child(1) p:before {
    content: "#b7f5e9"; }

/**====== Primary-color css end ======**/
/**====== Success-color css start ======**/
.success-colorr ul li:nth-child(14) {
  background-color: #33d176; }
  .success-colorr ul li:nth-child(14) p:before {
    content: "#33d176"; }

.success-colorr ul li:nth-child(13) {
  background-color: #3bd37c; }
  .success-colorr ul li:nth-child(13) p:before {
    content: "#3bd37c"; }

.success-colorr ul li:nth-child(12) {
  background-color: #44d581; }
  .success-colorr ul li:nth-child(12) p:before {
    content: "#44d581"; }

.success-colorr ul li:nth-child(11) {
  background-color: #4cd787; }
  .success-colorr ul li:nth-child(11) p:before {
    content: "#4cd787"; }

.success-colorr ul li:nth-child(10) {
  background-color: #54d98c; }
  .success-colorr ul li:nth-child(10) p:before {
    content: "#54d98c"; }

.success-colorr ul li:nth-child(9) {
  background-color: #5dda92; }
  .success-colorr ul li:nth-child(9) p:before {
    content: "#5dda92"; }

.success-colorr ul li:nth-child(8) {
  background-color: #65dc98; }
  .success-colorr ul li:nth-child(8) p:before {
    content: "#65dc98"; }

.success-colorr ul li:nth-child(7) {
  background-color: #6dde9d; }
  .success-colorr ul li:nth-child(7) p:before {
    content: "#6dde9d"; }

.success-colorr ul li:nth-child(6) {
  background-color: #76e0a3; }
  .success-colorr ul li:nth-child(6) p:before {
    content: "#76e0a3"; }

.success-colorr ul li:nth-child(5) {
  background-color: #7ee2a8; }
  .success-colorr ul li:nth-child(5) p:before {
    content: "#7ee2a8"; }

.success-colorr ul li:nth-child(4) {
  background-color: #86e4ae; }
  .success-colorr ul li:nth-child(4) p:before {
    content: "#86e4ae"; }

.success-colorr ul li:nth-child(3) {
  background-color: #8fe6b4; }
  .success-colorr ul li:nth-child(3) p:before {
    content: "#8fe6b4"; }

.success-colorr ul li:nth-child(2) {
  background-color: #97e8b9; }
  .success-colorr ul li:nth-child(2) p:before {
    content: "#97e8b9"; }

.success-colorr ul li:nth-child(1) {
  background-color: #9fe9bf; }
  .success-colorr ul li:nth-child(1) p:before {
    content: "#9fe9bf"; }

/**====== Success-color css end ======**/
/**====== Info-color css start ======**/
.info-colorr ul li:nth-child(14) {
  background-color: #3d9cdd; }
  .info-colorr ul li:nth-child(14) p:before {
    content: "#3d9cdd"; }

.info-colorr ul li:nth-child(13) {
  background-color: #45a1de; }
  .info-colorr ul li:nth-child(13) p:before {
    content: "#45a1de"; }

.info-colorr ul li:nth-child(12) {
  background-color: #4ea5e0; }
  .info-colorr ul li:nth-child(12) p:before {
    content: "#4ea5e0"; }

.info-colorr ul li:nth-child(11) {
  background-color: #57aae1; }
  .info-colorr ul li:nth-child(11) p:before {
    content: "#57aae1"; }

.info-colorr ul li:nth-child(10) {
  background-color: #5faee3; }
  .info-colorr ul li:nth-child(10) p:before {
    content: "#5faee3"; }

.info-colorr ul li:nth-child(9) {
  background-color: #68b2e4; }
  .info-colorr ul li:nth-child(9) p:before {
    content: "#68b2e4"; }

.info-colorr ul li:nth-child(8) {
  background-color: #71b7e6; }
  .info-colorr ul li:nth-child(8) p:before {
    content: "#71b7e6"; }

.info-colorr ul li:nth-child(7) {
  background-color: #79bbe7; }
  .info-colorr ul li:nth-child(7) p:before {
    content: "#79bbe7"; }

.info-colorr ul li:nth-child(6) {
  background-color: #82c0e9; }
  .info-colorr ul li:nth-child(6) p:before {
    content: "#82c0e9"; }

.info-colorr ul li:nth-child(5) {
  background-color: #8bc4ea; }
  .info-colorr ul li:nth-child(5) p:before {
    content: "#8bc4ea"; }

.info-colorr ul li:nth-child(4) {
  background-color: #93c8ec; }
  .info-colorr ul li:nth-child(4) p:before {
    content: "#93c8ec"; }

.info-colorr ul li:nth-child(3) {
  background-color: #9ccded; }
  .info-colorr ul li:nth-child(3) p:before {
    content: "#9ccded"; }

.info-colorr ul li:nth-child(2) {
  background-color: #a5d1ef; }
  .info-colorr ul li:nth-child(2) p:before {
    content: "#a5d1ef"; }

.info-colorr ul li:nth-child(1) {
  background-color: #add6f1; }
  .info-colorr ul li:nth-child(1) p:before {
    content: "#add6f1"; }

/**====== Info-color css end ======**/
/**====== Warning-color css start ======**/
.warning-colorr ul li:nth-child(14) {
  background-color: #f2c619; }
  .warning-colorr ul li:nth-child(14) p:before {
    content: "#f2c619"; }

.warning-colorr ul li:nth-child(13) {
  background-color: #f2c922; }
  .warning-colorr ul li:nth-child(13) p:before {
    content: "#f2c922"; }

.warning-colorr ul li:nth-child(12) {
  background-color: #f3cb2c; }
  .warning-colorr ul li:nth-child(12) p:before {
    content: "#f3cb2c"; }

.warning-colorr ul li:nth-child(11) {
  background-color: #f3cd36; }
  .warning-colorr ul li:nth-child(11) p:before {
    content: "#f3cd36"; }

.warning-colorr ul li:nth-child(10) {
  background-color: #f4d03f; }
  .warning-colorr ul li:nth-child(10) p:before {
    content: "#f4d03f"; }

.warning-colorr ul li:nth-child(9) {
  background-color: #f4d249; }
  .warning-colorr ul li:nth-child(9) p:before {
    content: "#f4d249"; }

.warning-colorr ul li:nth-child(8) {
  background-color: #f5d552; }
  .warning-colorr ul li:nth-child(8) p:before {
    content: "#f5d552"; }

.warning-colorr ul li:nth-child(7) {
  background-color: #f5d75c; }
  .warning-colorr ul li:nth-child(7) p:before {
    content: "#f5d75c"; }

.warning-colorr ul li:nth-child(6) {
  background-color: #f6d966; }
  .warning-colorr ul li:nth-child(6) p:before {
    content: "#f6d966"; }

.warning-colorr ul li:nth-child(5) {
  background-color: #f7dc6f; }
  .warning-colorr ul li:nth-child(5) p:before {
    content: "#f7dc6f"; }

.warning-colorr ul li:nth-child(4) {
  background-color: #f7de79; }
  .warning-colorr ul li:nth-child(4) p:before {
    content: "#f7de79"; }

.warning-colorr ul li:nth-child(3) {
  background-color: #f8e083; }
  .warning-colorr ul li:nth-child(3) p:before {
    content: "#f8e083"; }

.warning-colorr ul li:nth-child(2) {
  background-color: #f8e38c; }
  .warning-colorr ul li:nth-child(2) p:before {
    content: "#f8e38c"; }

.warning-colorr ul li:nth-child(1) {
  background-color: #f9e596; }
  .warning-colorr ul li:nth-child(1) p:before {
    content: "#f9e596"; }

/**====== Warning-color css end ======**/
/**====== Danger-color css start ======**/
.danger-colorr ul li:nth-child(14) {
  background-color: #e85445; }
  .danger-colorr ul li:nth-child(14) p:before {
    content: "#e85445"; }

.danger-colorr ul li:nth-child(13) {
  background-color: #e95d4e; }
  .danger-colorr ul li:nth-child(13) p:before {
    content: "#e95d4e"; }

.danger-colorr ul li:nth-child(12) {
  background-color: #ea6557; }
  .danger-colorr ul li:nth-child(12) p:before {
    content: "#ea6557"; }

.danger-colorr ul li:nth-child(11) {
  background-color: #eb6d60; }
  .danger-colorr ul li:nth-child(11) p:before {
    content: "#eb6d60"; }

.danger-colorr ul li:nth-child(10) {
  background-color: #ed7669; }
  .danger-colorr ul li:nth-child(10) p:before {
    content: "#ed7669"; }

.danger-colorr ul li:nth-child(9) {
  background-color: #ee7e72; }
  .danger-colorr ul li:nth-child(9) p:before {
    content: "#ee7e72"; }

.danger-colorr ul li:nth-child(8) {
  background-color: #ef867c; }
  .danger-colorr ul li:nth-child(8) p:before {
    content: "#ef867c"; }

.danger-colorr ul li:nth-child(7) {
  background-color: #f08f85; }
  .danger-colorr ul li:nth-child(7) p:before {
    content: "#f08f85"; }

.danger-colorr ul li:nth-child(6) {
  background-color: #f1978e; }
  .danger-colorr ul li:nth-child(6) p:before {
    content: "#f1978e"; }

.danger-colorr ul li:nth-child(5) {
  background-color: #f29f97; }
  .danger-colorr ul li:nth-child(5) p:before {
    content: "#f29f97"; }

.danger-colorr ul li:nth-child(4) {
  background-color: #f3a8a0; }
  .danger-colorr ul li:nth-child(4) p:before {
    content: "#f3a8a0"; }

.danger-colorr ul li:nth-child(3) {
  background-color: #f4b0a9; }
  .danger-colorr ul li:nth-child(3) p:before {
    content: "#f4b0a9"; }

.danger-colorr ul li:nth-child(2) {
  background-color: #f6b8b2; }
  .danger-colorr ul li:nth-child(2) p:before {
    content: "#f6b8b2"; }

.danger-colorr ul li:nth-child(1) {
  background-color: #f7c1bb; }
  .danger-colorr ul li:nth-child(1) p:before {
    content: "#f7c1bb"; }

/**====== Danger-color css end ======**/
/**====== Color-color css end ======**/
/**  =====================
      Draggable css start
==========================  **/
#draggableMultiple .sortable-moves {
  cursor: move;
  margin-bottom: 0;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.14);
  margin-bottom: 20px;
  padding: 15px 0 15px 60px; }

.sortable-moves {
  font-size: 14px;
  line-height: 1.55556em;
  list-style-type: none;
  margin-bottom: 15px;
  min-height: 3.55556em;
  padding-left: 5.11111em;
  position: relative;
  cursor: move; }
  .sortable-moves img {
    position: absolute;
    height: 40px;
    left: 10px;
    border-radius: 5px;
    top: 15px; }
  .sortable-moves h6 {
    font-weight: 500; }

.card-sub {
  cursor: move;
  border: none;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.05), 0 -2px 1px -2px rgba(0, 0, 0, 0.04), 0 0 0 -1px rgba(0, 0, 0, 0.05); }
  .card-sub:hover {
    box-shadow: 0 0 25px -5px #9e9c9e;
    transition: all 180ms linear; }

/**====== Draggable css end ======**/
/**  =====================
      Payment-card css start
==========================  **/
.payment-card .icofont-paypal-alt, .payment-card .icofont-visa-alt, .payment-card .icofont-mastercard {
  display: block;
  font-size: 60px;
  color: #ed5565; }

.payment-card .icofont-visa-alt {
  color: #1c84c6; }

.payment-card .icofont-mastercard {
  color: #f8ac59; }

.demo-container {
  padding-bottom: 0; }

.jp-card .jp-card-front,
.jp-card .jp-card-back {
  background: #448aff !important; }

.payment-form {
  max-width: 550px;
  margin: 0 auto;
  padding: 20px;
  padding-bottom: 0; }

/**====== Payment-card css end ======**/
/**  =====================
      Editor css start
==========================  **/
.ace_editor {
  width: 100% !important;
  position: relative !important;
  margin-top: 20px; }

.long-press:focus {
  outline-color: #448aff; }

.inputor:focus {
  border-color: #448aff; }

#edui1 {
  width: auto !important; }

/**====== Editor css end ======**/
/**  =====================
      FB-wall css start
==========================  **/
.tab-pane form .md-add-on i {
  font-size: 20px; }

.wall-elips {
  position: absolute;
  right: 15px; }

.social-wallpaper {
  position: relative; }

.social-profile {
  position: relative;
  padding-top: 15px; }

.timeline-btn {
  position: absolute;
  bottom: 0;
  right: 30px; }

.nav-tabs.md-tabs.tab-timeline li a {
  padding: 20px 0 10px;
  color: #37474f;
  font-size: 16px; }

.social-timeline-left {
  position: absolute;
  top: -200px;
  margin-right: 15px; }

.post-input {
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  resize: none; }

.user-box .media-object, .friend-box .media-object {
  height: 45px;
  width: 45px;
  display: inline-block;
  cursor: pointer; }

.friend-box img {
  margin-right: 10px;
  margin-bottom: 10px; }

.chat-header {
  color: #222222; }

.live-status {
  height: 9px;
  width: 9px;
  position: absolute;
  bottom: 0;
  right: 17px;
  border-radius: 100%;
  border: 1px solid;
  top: 5px; }

.tab-timeline .slide {
  bottom: -1px; }

.image-upload input {
  visibility: hidden;
  max-width: 0;
  max-height: 0; }

.file-upload-lbl {
  max-width: 15px;
  padding: 5px 0 0; }

.ellipsis::after {
  top: 15px;
  border: none;
  position: absolute;
  content: '\f142';
  font-family: FontAwesome;
  right: 30px; }

.elipsis-box {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.11);
  top: 40px;
  right: -10px; }
  .elipsis-box:after {
    content: '';
    height: 13px;
    width: 13px;
    background: #fff;
    position: absolute;
    top: -5px;
    right: 10px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
    box-shadow: -3px -3px 11px 1px rgba(170, 170, 170, 0.22); }

.friend-elipsis {
  left: -10px;
  top: -10px; }

.social-profile:hover .profile-hvr, .social-wallpaper:hover .profile-hvr {
  opacity: 1;
  transition: all ease-in-out 0.3s; }

.profile-hvr {
  opacity: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  font-size: 20px;
  padding: 10px;
  top: 0;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.61);
  transition: all ease-in-out 0.3s; }

.social-profile {
  margin: 0 15px; }

.social-follower {
  text-align: center; }
  .social-follower h4 {
    font-size: 18px;
    margin-bottom: 10px;
    font-style: normal; }
  .social-follower h5 {
    font-size: 14px; }
  .social-follower .follower-counter {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 13px; }
    .social-follower .follower-counter .txt-primary {
      font-size: 24px; }

.timeline-icon {
  height: 45px;
  width: 45px;
  display: block;
  margin: 0 auto;
  border: 4px #fff solid; }

.social-timelines-left:after {
  height: 3px;
  width: 20px;
  position: absolute;
  background: #cccccc;
  top: 20px;
  content: "";
  right: -10px;
  z-index: 0; }

.social-timelines-left {
  position: relative;
  z-index: 3; }

.social-timelines:before {
  position: absolute;
  content: ' ';
  width: 3px;
  background: #cccccc;
  left: 48px;
  height: 100%;
  top: 0;
  z-index: 1; }

.timeline-dot:after, .timeline-dot:before {
  content: "";
  position: absolute;
  height: 9px;
  width: 9px;
  background-color: #cccccc;
  left: 45px;
  border-radius: 100%; }

.user-box .social-designation, .post-timelines .social-time {
  font-size: 13px; }

.user-box .f-right {
  text-transform: capitalize; }

.social-msg a {
  display: inline-block; }
  .social-msg a span {
    color: #666;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 10px; }
  .social-msg a:hover {
    color: #448aff; }
    .social-msg a:hover span, .social-msg a:hover i {
      color: #448aff !important; }

.view-info .social-label, .contact-info .social-label, .work-info .social-label {
  font-size: 15px;
  padding-left: 0;
  padding-top: 0; }

.view-info .social-user-name, .contact-info .social-user-name, .work-info .social-user-name {
  font-size: 14px;
  padding-left: 0; }

.friend-elipsis .social-designation {
  font-size: 13px; }

.timeline-details p {
  padding-top: 10px;
  margin-bottom: 0; }

.post-timelines {
  cursor: pointer; }

.timeline-details .chat-header, .post-timelines .chat-header {
  font-size: 16px;
  font-weight: 500; }

.social-client-description {
  padding-bottom: 20px;
  margin-bottom: 20px; }
  .social-client-description p {
    margin-top: 5px;
    margin-bottom: 0; }
  .social-client-description span {
    font-size: 12px;
    margin-left: 10px; }
  .social-client-description .chat-header {
    font-size: 14px;
    font-weight: 500;
    cursor: pointer; }

.social-tabs a {
  font-size: 18px; }

.timeline-btn a {
  margin-bottom: 20px; }

.profile-hvr i {
  cursor: pointer; }

/*====== FB-wall css End ======*/
/**  =====================
      Full-calender css start
==========================  **/
.fc-left {
  margin-bottom: 10px; }

#external-events h4 {
  font-size: 16px;
  margin-top: 0;
  padding-top: 1em; }

#external-events .fc-event {
  background: #fff;
  color: #000;
  cursor: move;
  padding: 5px 18px;
  margin-bottom: 5px; }

#external-events p {
  margin: 1.5em 0;
  font-size: 11px;
  color: #666; }

#external-events p input {
  margin: 0;
  vertical-align: middle; }

.fc th,
.fc td {
  line-height: 2.5; }

.fc-state-default {
  background-color: #fff;
  color: #272727; }

tr:first-child > td > .fc-day-grid-event {
  color: #000;
  padding-left: 10px;
  background: #f3f3f3; }

.fc-nonbusiness {
  background-color: #fff; }

.fc-state-active {
  background-color: #448aff;
  color: #fff; }

.fc-toolbar {
  margin-bottom: 8px; }

.fc-toolbar .fc-center {
  display: block; }

.fc-center h2 {
  font-weight: 300;
  font-size: 25px;
  margin-top: 10px; }

.fc button {
  height: auto;
  padding: 10px; }

.fc-toolbar .fc-left {
  float: right; }

.fc-button-group {
  margin-right: 10px; }

.fc .fc-toolbar > * > * {
  margin-left: 0; }

.fc-event,
.fc-event-dot {
  background-color: transparent; }

.fc-day-grid-event:hover {
  color: #000 !important;
  opacity: 0.8; }

.fc-time-grid-event .fc-content {
  color: #fff; }

.fc-bgevent {
  /* default look for background events */
  background: #d8d6d6 !important; }

.fc-state-default {
  background-image: none !important;
  border-color: none !important;
  text-shadow: none !important;
  box-shadow: none !important; }

.fc-state-down,
.fc-state-active {
  background-color: none !important;
  background-image: none !important;
  box-shadow: none !important; }

.fc th {
  background: #448aff;
  color: #fff;
  border-color: #448aff; }

.fc-event-container a {
  padding: 5px; }

.fc-event {
  line-height: 1.3;
  border-radius: 2px; }

tr:first-child > td > .fc-day-grid-event {
  margin-left: 0; }

.clndr {
  width: 100%; }

.clndr {
  background-color: #F3F3F3;
  box-shadow: 0 4 px 0 #272727; }

.clndr .clndr-controls {
  padding: 14px;
  background-color: #303549;
  color: #fff;
  text-align: center; }

.clndr.clndr-controls.clndr-previous-button {
  float: left;
  text-align: left; }

.clndr.clndr-controls.clndr-next-button {
  float: right;
  text-align: right; }

.clndr.clndr-controls.clndr-next-button,
.clndr.clndr-controls.clndr-previous-button {
  width: 30px;
  cursor: pointer; }

.clndr.clndr-controls.clndr-next-button:hover,
.clndr.clndr-controls.clndr-previous-button:hover {
  opacity: .5; }

.clndr.clndr-grid {
  float: left;
  width: 65%;
  background-color: #FFF; }

.clndr.clndr-grid.days-of-the-week {
  width: 100%;
  background-color: #F6BB42; }

.clndr.clndr-grid.days-of-the-week.header-day {
  float: left;
  width: 14.2857%;
  padding: 14px;
  text-align: center;
  color: #fff; }

.clndr.clndr-grid.days {
  width: 100%; }

.clndr.clndr-grid.days.day,
.clndr.clndr-grid.days.empty {
  float: left;
  width: 14.2857%;
  padding: 12px 0;
  text-align: center;
  color: #4f4f4f; }

.clndr .clndr-grid .days .day.event .day-number,
.clndr .clndr-grid .days .empty.event .day-number {
  border: 1px solid #ff5252;
  border-radius: 50%;
  padding: 6px 9px; }

.clndr.clndr-grid.days.day.adjacent-month.day-number,
.clndr.clndr-grid.days.day.inactive.day-number,
.clndr.clndr-grid.days.empty.adjacent-month.day-number {
  opacity: .3;
  cursor: pointer; }

.clndr.clndr-grid.days.day.selected {
  background-color: #ff5252;
  color: #fff; }

.clndr.clndr-grid.days.today {
  color: #fff;
  background-image: none; }

.clndr.event-listing {
  background-color: #448aff;
  float: left;
  width: 35%; }

.clndr.event-listing.event-listing-title {
  padding: 15.5px;
  font-size: .88rem;
  background-color: #ffba57;
  text-align: center;
  color: #fff;
  letter-spacing: 1px; }

.clndr.event-listing.event-item {
  padding: 6px 14px;
  color: #4f4f4f; }

.clndr .event-listing .event-item-location {
  font-weight: 400; }

.fc-event {
  border-color: #448aff; }

.clndr .clndr-grid .days .day.event .day-number,
.clndr .clndr-grid .days .empty.event .day-number {
  color: #448aff;
  border: none;
  padding: 0; }

/**====== Full-calender css end ======**/
/**  =====================
      Job-pages css start
==========================  **/
.job-right-header .card-header .card-header-right {
  top: 7px;
  right: 15px; }

.job-right-header .radio-inline {
  display: block; }

.job-right-header .checkbox-fade {
  display: block; }
  .job-right-header .checkbox-fade label {
    position: absolute;
    z-index: 99; }
  .job-right-header .checkbox-fade div {
    position: relative;
    padding-left: 40px;
    margin-bottom: 20px; }

.job-right-header a {
  color: #448aff;
  font-weight: 500; }

.job-details-list {
  padding-left: inherit;
  list-style-type: square;
  margin-bottom: 40px; }
  .job-details-list li {
    margin-bottom: 10px; }

.company-name p {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 5px; }

.job-cards .media {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #ddd; }

.job-cards:last-child .media {
  border-bottom: none;
  padding-bottom: 0; }

.job-cards img {
  width: 60px; }

.job-cards .media-right {
  position: absolute;
  top: 20px;
  right: 0; }

@media only screen and (max-width: 575px) {
  .job-cards .media-left {
    padding-right: 0; } }

.job-badge {
  position: absolute;
  top: 35px;
  right: -15px; }
  .job-badge:after {
    content: "";
    border: 7px solid transparent;
    border-top-color: #005cf7;
    position: absolute;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -7px;
    right: 8px;
    z-index: 1; }
  .job-badge .label {
    width: 50px;
    text-align: center;
    padding: 7px;
    border-radius: 0;
    z-index: 10; }
  @media only screen and (max-width: 768px) {
    .job-badge {
      display: none; } }

.job-lable {
  margin-bottom: 20px; }
  .job-lable .label {
    margin: 5px 10px 5px 0;
    position: relative;
    z-index: 9;
    display: inline-block;
    padding: 5px 10px 5px 12px; }

.job-card-desc {
  font-weight: 500;
  margin-top: 10px;
  cursor: pointer; }

.job-meta-data {
  text-transform: capitalize;
  font-size: 12px;
  margin-bottom: 10px; }
  .job-meta-data i {
    margin-right: 5px;
    color: #448aff; }

/**====== Job-pages css end ======**/
/**  =====================
      Mail-pages css start
==========================  **/
.card-block.email-card {
  padding: 10px 25px; }

.email-card .user-head {
  background-color: #448aff;
  padding: 10px;
  min-height: 81px;
  display: flex;
  align-items: center; }
  .email-card .user-head .user-name {
    margin-left: 10px;
    margin-bottom: 10px; }
    .email-card .user-head .user-name span {
      display: block;
      color: #fff; }

.email-card label {
  margin-bottom: 0; }

.email-card .user-body {
  margin-left: -15px;
  margin-right: -15px;
  background-color: #fff; }
  .email-card .user-body .page-list {
    border-bottom: 0px solid transparent; }
    .email-card .user-body .page-list li {
      text-transform: capitalize;
      border-bottom: 1px solid #e9ecef;
      transition: all ease-in .3s;
      cursor: pointer; }
      .email-card .user-body .page-list li.mail-section {
        display: block;
        margin-bottom: 0; }
        .email-card .user-body .page-list li.mail-section a {
          padding: 15px 20px;
          border-radius: 0px !important;
          color: #37474f;
          border: 0px solid transparent; }
          .email-card .user-body .page-list li.mail-section a.active, .email-card .user-body .page-list li.mail-section a:hover {
            color: #448aff;
            background-color: #ddeaff; }
      .email-card .user-body .page-list li i {
        margin-right: 10px; }
      .email-card .user-body .page-list li:last-child {
        border-bottom: 0px solid transparent; }
  .email-card .user-body .label-list a {
    padding: 10px 20px;
    display: block;
    color: #6a6a6a;
    position: relative;
    padding-left: 25px; }
    .email-card .user-body .label-list a:before {
      content: '';
      position: absolute;
      height: 10px;
      width: 10px;
      border-radius: 50px;
      left: 0;
      top: 16px; }
    .email-card .user-body .label-list a.mail-work:before {
      background-color: #448aff; }
    .email-card .user-body .label-list a.mail-design:before {
      background-color: #00bcd4; }
    .email-card .user-body .label-list a.mail-family:before {
      background-color: #9ccc65; }
    .email-card .user-body .label-list a.mail-friends:before {
      background-color: #ffba57; }
    .email-card .user-body .label-list a.mail-office:before {
      background-color: #ff5252; }

.email-card .mail-box-head {
  background-color: #448aff;
  min-height: 80px;
  padding: 20px 0 0; }
  .email-card .mail-box-head .input-group {
    margin-bottom: 0; }
  .email-card .mail-box-head .form-control, .email-card .mail-box-head .float-label, .email-card .mail-box-head .material-addone {
    color: #fff; }

.email-card .mail-body {
  padding: 20px 0; }
  .email-card .mail-body .mail-body-header {
    margin-bottom: 20px; }
    .email-card .mail-body .mail-body-header .btn, .email-card .mail-body .mail-body-header .btn-group {
      margin: 2px; }

.email-card .mail-body-content .check-star {
  display: flex;
  align-items: center; }
  .email-card .mail-body-content .check-star .icofont-star {
    margin-top: -8px; }

.email-card .mail-body-content tr {
  transition: all ease-in .3s; }

.email-card .mail-body-content tr:hover {
  background-color: #ddeaff;
  cursor: pointer; }

.email-card .mail-body-content .unread a {
  color: #222;
  font-weight: 500; }

.email-card .mail-body-content .read a {
  color: #222;
  font-weight: 400; }

.email-read .photo-table {
  padding-right: 10px; }

.email-read .user-name {
  margin-bottom: 10px; }

.mail-body-content.email-read .card {
  box-shadow: none;
  margin-bottom: 0; }

.user-name {
  margin-bottom: 20px; }

.email-card .user-mail h6 {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 20px;
  color: #292b2c; }

.email-content {
  line-height: 30px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px; }

.mail-img {
  margin-top: 20px; }

.email-welcome-txt {
  font-weight: 500; }

/**====== Mail-pages css end ======**/
.set-map {
  height: 400px;
  width: 100% !important; }

#mapGeo {
  height: 345px; }

#address-search .input-group {
  margin-bottom: 0; }

/**  =====================
      Note css start
==========================  **/
.note-card {
  padding-top: 0;
  padding-bottom: 0; }
  .note-card .note-box-aside {
    border-right: 1px solid #ddd; }
    .note-card .note-box-aside .row {
      padding: 20px 0;
      display: flex;
      align-items: center; }
  .note-card .Note-header {
    padding: 20px 0; }
  .note-card .note-write {
    position: relative;
    background: -webkit-linear-gradient(top, #91baff 0%, #ddeaff 5%) 0 0;
    background-size: 100% 35px; }
    .note-card .note-write:before {
      content: '';
      position: absolute;
      width: 0px;
      top: 0;
      left: 32px;
      bottom: 0;
      border-left: 1px solid #448aff; }
    .note-card .note-write:after {
      content: '';
      position: absolute;
      width: 0px;
      top: 0;
      left: 34px;
      bottom: 0;
      border-left: 1px solid #448aff; }
  .note-card #Note-pad {
    border: none;
    resize: none;
    background: transparent;
    padding: 0px 20px 0 50px;
    line-height: 35px; }
  .note-card .Note-created__on {
    display: block; }

/**====== Note css end ======**/
/**  =====================
      Prism css start
==========================  **/
pre[class*="language-"] {
  background-color: #fff;
  border: 1px solid #ddd; }
  pre[class*="language-"] code {
    background-color: #fff !important; }

pre.line-numbers {
  position: relative;
  padding-left: 3.8em;
  counter-reset: linenumber; }

pre.line-numbers > code {
  position: relative; }

.line-numbers .line-numbers-rows {
  position: absolute;
  pointer-events: none;
  top: 0;
  font-size: 100%;
  left: -3.8em;
  width: 3em;
  /* works for line-numbers below 1000 lines */
  letter-spacing: -1px;
  border-right: 1px solid #999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.line-numbers-rows > span {
  pointer-events: none;
  display: block;
  counter-increment: linenumber; }

.line-numbers-rows > span:before {
  content: counter(linenumber);
  color: #999;
  display: block;
  padding-right: 0.8em;
  text-align: right; }

/**====== Prism css end ======**/
/**  =====================
      Pre-loader css start
==========================  **/
/*===== Loader1 =====*/
.loader {
  margin: 18px auto;
  top: 65px;
  left: 65px;
  right: 0;
  width: 100px;
  height: 100px;
  position: relative;
  transform: translate(-50%, -50%); }
  .loader.animation-start .circle {
    animation: rotating 2s ease-in-out infinite; }
  .loader.animation-stop .circle {
    animation-iteration-count: 1; }
  .loader .circle {
    width: 50px;
    height: 50px;
    display: block;
    position: absolute;
    transform-origin: 50% 50%;
    animation-iteration-count: 0; }
  .loader .circle:after {
    content: '';
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: #1abc9c; }
  .loader .circle.delay-1 {
    animation-delay: 0.125s; }
  .loader .circle.size-1:after {
    width: 11px;
    height: 11px; }
  .loader .circle.delay-2 {
    animation-delay: 0.25s; }
  .loader .circle.size-2:after {
    width: 12px;
    height: 12px; }
  .loader .circle.delay-3 {
    animation-delay: 0.375s; }
  .loader .circle.size-3:after {
    width: 13px;
    height: 13px; }
  .loader .circle.delay-4 {
    animation-delay: 0.5s; }
  .loader .circle.size-4:after {
    width: 14px;
    height: 14px; }
  .loader .circle.delay-5 {
    animation-delay: 0.625s; }
  .loader .circle.size-5:after {
    width: 15px;
    height: 15px; }
  .loader .circle.delay-6 {
    animation-delay: 0.75s; }
  .loader .circle.size-6:after {
    width: 16px;
    height: 16px; }
  .loader .circle.delay-7 {
    animation-delay: 0.875s; }
  .loader .circle.size-7:after {
    width: 17px;
    height: 17px; }
  .loader .circle.delay-8 {
    animation-delay: 1s; }
  .loader .circle.size-8:after {
    width: 18px;
    height: 18px; }
  .loader .circle.delay-9 {
    animation-delay: 1.125s; }
  .loader .circle.size-9:after {
    width: 19px;
    height: 19px; }
  .loader .circle.delay-10 {
    animation-delay: 1.25s; }
  .loader .circle.size-10:after {
    width: 20px;
    height: 20px; }
  .loader .circle.delay-11 {
    animation-delay: 1.375s; }
  .loader .circle.size-11:after {
    width: 21px;
    height: 21px; }
  .loader .circle.delay-12 {
    animation-delay: 1.5s; }
  .loader .circle.size-12:after {
    width: 22px;
    height: 22px; }

@keyframes rotating {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/*===== Loader2 =====*/
#loader2 {
  width: 80px;
  height: 130px;
  display: block;
  transform-origin: 50% 50%;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  animation: spin 1s linear infinite;
  margin: 0 auto; }

#circle-loader2 {
  fill: none;
  stroke: #448aff;
  stroke-width: 4;
  animation: draw 3s ease-in-out infinite; }

@keyframes draw {
  0% {
    stroke-dasharray: 20, 282.6; }
  50% {
    stroke-dasharray: 200, 282.6; }
  100% {
    stroke-dasharray: 20, 282.6; } }

@keyframes spin {
  to {
    transform: rotate(360deg); } }

/*==== Loader3 ====*/
.preloader3 {
  width: auto;
  height: 130px;
  text-align: center;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .preloader3 > div {
    margin: 2px;
    background-color: #448aff;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    -webkit-animation: stretchdelay 0.7s infinite ease-in-out;
    animation: stretchdelay 0.7s infinite ease-in-out; }
  .preloader3 .circ2 {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s; }
  .preloader3 .circ3 {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s; }
  .preloader3 .circ4 {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s; }
  .preloader3 .circ5 {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s; }
  .preloader3 .loader-md {
    height: 13px;
    width: 13px; }
  .preloader3 .loader-lg {
    height: 18px;
    width: 18px; }

@-webkit-keyframes stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: translateY(-10px); }
  20% {
    -webkit-transform: translateY(-20px); } }

@keyframes stretchdelay {
  0%,
  40%,
  100% {
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px); }
  20% {
    transform: translateY(-20px);
    -webkit-transform: translateY(-20px); } }

@keyframes rotateColor {
  0% {
    border-top-color: #1b8bf9; }
  12.5% {
    border-bottom-color: rgba(27, 139, 249, 0.8); }
  25% {
    border-bottom-color: rgba(27, 139, 249, 0.6); }
  37.5% {
    border-bottom-color: rgba(27, 139, 249, 0.4); }
  50% {
    border-bottom-color: rgba(27, 139, 249, 0.6); }
  50.00001% {
    border-bottom-color: #1b8bf9; }
  62.5% {
    border-bottom-color: rgba(27, 139, 249, 0.8); }
  75% {
    border-bottom-color: rgba(27, 139, 249, 0.6); }
  87.5% {
    border-bottom-color: rgba(27, 139, 249, 0.4); } }

/*===== Loader4 =====*/
.preloader4 {
  width: 30px;
  height: 30px;
  position: relative;
  margin: 55px auto; }

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #448aff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: bounce 2.0s infinite ease-in-out;
  animation: bounce 2.0s infinite ease-in-out;
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s; }

@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: scale(0); }
  50% {
    -webkit-transform: scale(1); } }

@keyframes bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

/*===== Loader5 =====*/
.preloader5 {
  margin: 55px 0;
  text-align: center; }
  .preloader5 .circle-5 {
    display: inline-block;
    padding: 11px;
    margin: 0 0.6em;
    background: #448aff;
    border-radius: 100%; }
  .preloader5 .l {
    -webkit-animation: pulse 2s infinite linear;
    animation: pulse 2s infinite linear; }
  .preloader5 .m {
    -webkit-animation: pulse 2s infinite linear;
    animation: pulse 2s infinite linear;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s; }
  .preloader5 .r {
    -webkit-animation: pulse 2s infinite linear;
    animation: pulse 2s infinite linear;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s; }

@-webkit-keyframes pulse {
  10% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  20% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  30% {
    -webkit-transform: scale(1.7);
    transform: scale(1.7); }
  50% {
    -webkit-transform: scale(1.7);
    transform: scale(1.7); }
  70% {
    -webkit-transform: scale(1.7);
    transform: scale(1.7); }
  80% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  90% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes pulse {
  10% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  20% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  30% {
    -webkit-transform: scale(1.7);
    transform: scale(1.7); }
  50% {
    -webkit-transform: scale(1.7);
    transform: scale(1.7); }
  70% {
    -webkit-transform: scale(1.7);
    transform: scale(1.7); }
  80% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  90% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

/*===== Loader6 =====*/
.preloader6 {
  border-radius: 100%;
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: inherit;
  margin: 18px 0; }

.preloader6 hr {
  border: 0;
  background: inherit;
  width: 80%;
  height: 80%;
  margin: 10%;
  border-radius: 100%; }

.preloader6::before,
.preloader6::after,
.preloader6 hr::before,
.preloader6 hr::after {
  content: '';
  position: absolute;
  top: 0;
  z-index: -1;
  left: 33px;
  border: 17px solid transparent;
  border-bottom: 50px solid #448aff;
  border-top: 50px solid #448aff;
  animation: rotateColor 1000ms infinite; }

.preloader6::after {
  transform: rotate(45deg);
  animation-delay: 125ms; }

.preloader6 hr::before {
  transform: rotate(90deg);
  animation-delay: 250ms; }

.preloader6 hr::after {
  transform: rotate(135deg);
  animation-delay: 375ms; }

.loader-block,
.loader {
  display: flex;
  align-items: center;
  justify-content: center; }

@keyframes rotateColor {
  0% {
    border-top-color: #1abc9c; }
  12.5% {
    border-bottom-color: rgba(26, 188, 156, 0.8); }
  25% {
    border-bottom-color: rgba(26, 188, 156, 0.6); }
  37.5% {
    border-bottom-color: rgba(26, 188, 156, 0.4); }
  50% {
    border-bottom-color: rgba(26, 188, 156, 0.6); }
  50.00001% {
    border-bottom-color: #1abc9c; }
  62.5% {
    border-bottom-color: rgba(26, 188, 156, 0.8); }
  75% {
    border-bottom-color: rgba(26, 188, 156, 0.6); }
  87.5% {
    border-bottom-color: rgba(26, 188, 156, 0.4); } }

/*========= Pre-loader css end ============*/
/*======= Loader-color css starts  ======= */
.loader-primary {
  background-color: #448aff !important; }

.loader-warning {
  background-color: #ffba57 !important; }

.loader-default {
  background-color: #d6d6d6 !important; }

.loader-danger {
  background-color: #ff5252 !important; }

.loader-success {
  background-color: #9ccc65 !important; }

.loader-inverse {
  background-color: #37474f !important; }

.loader-info {
  background-color: #00bcd4 !important; }

/*======= Loader-color css ends  ======= */
/*range slider start */
.range-slider {
  min-height: 200px;
  display: flex;
  align-items: center;
  margin-left: 15px; }
  .range-slider b {
    margin-top: -10px; }

#RGB {
  height: 10px;
  background: gray; }

#RC .slider-selection {
  background: #FF8282; }

#RC .slider-handle {
  background: red; }

#GC .slider-selection {
  background: #428041; }

#GC .slider-handle {
  background: green; }

#BC .slider-selection {
  background: #8283FF; }

#BC .slider-handle {
  border-bottom-color: blue; }

#R,
#G,
#B {
  width: 300px; }

.range-slider-contain {
  position: absolute;
  bottom: 15px; }

.slider-handle {
  top: -5px;
  background-image: linear-gradient(to bottom, #448aff 0%, #448aff 100%);
  box-shadow: none; }

.slider-tick,
.slider-track {
  background-image: linear-gradient(to bottom, #BDC3C7 0%, #BDC3C7 100%); }

.slider-handle.custom::before {
  line-height: 15px;
  font-size: 35px;
  color: #448aff; }

#slider12a .slider-track-high,
#slider12c .slider-track-high {
  background: green; }

#slider12b .slider-track-low,
#slider12c .slider-track-low {
  background: red; }

#slider12c .slider-selection {
  background: yellow; }

.slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #77aaff 0%, #77aaff 100%); }

.slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #77aaff 0%, #77aaff 100%); }

.slider.slider-horizontal .slider-track {
  height: 2px; }

.slider.slider-horizontal .slider-tick,
.slider.slider-horizontal .slider-handle {
  top: -5px;
  cursor: pointer; }

/*range slider end */
.slider.slider-horizontal .slider-track {
  width: 90%;
  margin-top: -5px;
  top: 50%;
  left: 10px; }

/**  =====================
      Rating css start
==========================  **/
.br-wrapper {
  margin: 20px 0 50px; }

.br-theme-bars-1to10 .br-widget .br-current-rating {
  clear: both;
  padding: 0;
  font-size: 13px;
  color: #757575;
  font-weight: 400; }

.br-theme-bars-reversed .br-widget .br-current-rating {
  clear: both;
  padding: 0;
  line-height: 2;
  font-size: 13px; }

.br-theme-bars-movie .br-widget .br-current-rating {
  color: #757575;
  font-weight: 400;
  text-align: left; }

.br-theme-bars-horizontal .br-widget .br-current-rating,
.br-theme-bars-reversed .br-widget .br-current-rating {
  color: #757575;
  font-weight: 400;
  font-size: 13px; }

span.value {
  display: inline; }

.current-rating {
  position: relative;
  top: -50px; }
  .current-rating.hidden {
    display: none; }

.your-rating {
  position: relative;
  top: -50px; }
  .your-rating.hidden {
    display: none; }

.br-theme-bars-1to10 .br-widget a,
.br-theme-bars-movie .br-widget a,
.br-theme-bars-reversed .br-widget a,
.br-theme-bars-horizontal .br-widget a {
  background-color: #6da4ff; }
  .br-theme-bars-1to10 .br-widget a.br-active, .br-theme-bars-1to10 .br-widget a.br-selected,
  .br-theme-bars-movie .br-widget a.br-active,
  .br-theme-bars-movie .br-widget a.br-selected,
  .br-theme-bars-reversed .br-widget a.br-active,
  .br-theme-bars-reversed .br-widget a.br-selected,
  .br-theme-bars-horizontal .br-widget a.br-active,
  .br-theme-bars-horizontal .br-widget a.br-selected {
    background-color: #448aff; }

.br-theme-bars-pill .br-widget a {
  background-color: #6da4ff;
  color: #448aff; }
  .br-theme-bars-pill .br-widget a.br-active, .br-theme-bars-pill .br-widget a.br-selected {
    background-color: #448aff; }

.br-theme-fontawesome-stars .br-widget a.br-active:after,
.br-theme-fontawesome-stars .br-widget a.br-selected:after {
  color: #448aff; }

.br-theme-css-stars .br-widget a.br-active:after,
.br-theme-css-stars .br-widget a.br-selected:after {
  color: #448aff; }

.br-theme-bars-square .br-widget a {
  border: 2px solid #6da4ff;
  color: #6da4ff; }
  .br-theme-bars-square .br-widget a.br-active, .br-theme-bars-square .br-widget a.br-selected {
    border: 2px solid #448aff;
    color: #448aff; }

.br-theme-fontawesome-stars-o .br-widget a.br-selected:after,
.br-theme-fontawesome-stars-o .br-widget a:after,
.br-theme-fontawesome-stars-o .br-widget a.br-active:after,
.br-theme-fontawesome-stars-o .br-widget a.br-fractional:after {
  color: #448aff; }

/**====== Rating css end ======**/
/**  =====================
      Todo css start
==========================  **/
#task-container ul {
  overflow: hidden; }

#task-container .task-headline {
  display: none;
  color: #666666;
  border-bottom: 1px solid #C8C7BB;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-size: 1.6em;
  position: relative; }
  #task-container .task-headline:before {
    height: 1px;
    width: 100%;
    background: #FFF;
    position: absolute;
    content: " ";
    bottom: 0;
    left: 0; }

#task-container .nothing-message {
  height: 160px;
  color: #666;
  background-size: 15%; }

#task-container li {
  float: left;
  width: 49%;
  overflow: auto;
  height: auto;
  min-height: 10px;
  background: #FFF;
  display: inline-block;
  padding: 20px;
  border: 1px solid #CCC;
  color: #666;
  border-top: 9px solid #448aff;
  cursor: pointer;
  margin-bottom: 20px;
  margin-right: 2%;
  transition: all 0.3s;
  position: relative; }
  #task-container li:nth-child(even) {
    margin-right: 0; }
  #task-container li:hover {
    opacity: 1;
    border-top: 9px solid #d6d6d6; }
  #task-container li.complete {
    opacity: 1;
    border-top: 9px solid #ff5252;
    transition: all ease-in 0.3s; }
    #task-container li.complete:before {
      background: url("../assets/images/complete.png") no-repeat;
      position: absolute;
      top: 5px;
      right: 5px;
      content: "";
      width: 55px;
      height: 55px;
      background-size: 100%; }
    #task-container li.complete:hover {
      border-top: 9px solid #d6d6d6;
      opacity: 1; }
    #task-container li.complete p {
      text-decoration: line-through; }

#task-container p {
  line-height: 1.6em;
  text-align: left; }

.add-line.complete {
  text-decoration: line-through; }

.task-panel .to-do-label {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px; }
  .task-panel .to-do-label:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding: 0; }

.to-do-list {
  padding-bottom: 10px;
  margin-bottom: 20px; }
  .to-do-list i {
    color: #ccc;
    font-size: 17px;
    opacity: 0; }
  .to-do-list:last-child {
    border: none;
    padding: 0;
    margin: 0; }
  .to-do-list:hover i {
    opacity: 1;
    transition: opacity ease-in 0.3s; }
  .to-do-list p {
    display: inline-block; }

.to-do-label .checkbox-fade {
  display: block; }
  .to-do-label .checkbox-fade .delete_todo {
    float: right;
    font-size: 24px;
    color: #ccc; }

.to-do-label .check-task {
  display: block; }

.to-do-label i {
  cursor: pointer; }

.done-task span,
.done-task .captions {
  color: #919aa3;
  text-decoration: line-through; }

/**====== Todo css end ======**/
/**  =====================
      Notification css start
==========================  **/
.location-selector {
  width: 100%;
  height: 250px;
  background-color: #fff;
  border: 2px dashed #e5e9ec;
  position: relative;
  margin-bottom: 20px; }
  .location-selector .bit {
    background-color: #e5e9ec;
    cursor: pointer;
    position: absolute; }
    .location-selector .bit:hover {
      background-color: #ddd; }
    .location-selector .bit.bottom, .location-selector .bit.top {
      height: 25%;
      width: 40%;
      margin: 0 30%; }
    .location-selector .bit.top {
      top: 0; }
    .location-selector .bit.bottom {
      bottom: 0; }
    .location-selector .bit.left, .location-selector .bit.right {
      height: 20%;
      width: 20%;
      margin-left: 0;
      margin-right: 0; }
    .location-selector .bit.right {
      right: 0; }
    .location-selector .bit.left {
      left: 0; }

button.close {
  margin-top: 7px;
  margin-bottom: 0; }

/**====== Notification css end ======**/
/**  =====================
      Tooltip css start
==========================  **/
.mytooltip {
  display: inline;
  position: relative;
  z-index: 999; }
  .mytooltip .tooltip-item {
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    padding: 0 10px; }
  .mytooltip .tooltip-content {
    position: absolute;
    z-index: 9999;
    width: 360px;
    left: 50%;
    margin: 0 0 20px -180px;
    bottom: 100%;
    text-align: left;
    font-size: 14px;
    line-height: 30px;
    box-shadow: -5px -5px 15px rgba(48, 54, 61, 0.2);
    background: #2b2b2b;
    opacity: 0;
    cursor: default;
    pointer-events: none; }
    .mytooltip .tooltip-content::after {
      content: '';
      top: 100%;
      left: 50%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: #2a3035 transparent transparent;
      border-width: 10px;
      margin-left: -10px; }
    .mytooltip .tooltip-content img {
      position: relative;
      height: 140px;
      display: block;
      float: left;
      margin-right: 1em; }
  .mytooltip .tooltip-item::after {
    content: '';
    position: absolute;
    width: 360px;
    height: 20px;
    bottom: 100%;
    left: 50%;
    pointer-events: none;
    transform: translateX(-50%); }
  .mytooltip:hover .tooltip-item::after {
    pointer-events: auto; }
  .mytooltip:hover .tooltip-content {
    pointer-events: auto;
    opacity: 1;
    transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0deg); }
  .mytooltip:hover .tooltip-content2 {
    opacity: 1;
    font-size: 18px; }
    .mytooltip:hover .tooltip-content2 i {
      opacity: 1;
      font-size: 18px; }
  .mytooltip:hover .tooltip-content2 {
    opacity: 1;
    font-size: 18px;
    pointer-events: auto;
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1); }
    .mytooltip:hover .tooltip-content2 i {
      opacity: 1;
      font-size: 18px;
      pointer-events: auto;
      transform: translate3d(0, 0, 0) scale3d(1, 1, 1); }
  .mytooltip:hover .tooltip-item2 {
    color: #fff;
    transform: translate3d(0, -0.9em, 0); }
  .mytooltip:hover .tooltip-text3 {
    transition-delay: 0s;
    transform: scale3d(1, 1, 1); }
  .mytooltip:hover .tooltip-content3 {
    opacity: 1;
    pointer-events: auto;
    transform: scale3d(1, 1, 1); }
  .mytooltip:hover .tooltip-content4 {
    pointer-events: auto;
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  .mytooltip:hover .tooltip-text2 {
    pointer-events: auto;
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  .mytooltip:hover .tooltip-inner2 {
    transition-delay: 0.3s;
    transform: translate3d(0, 0, 0); }
  .mytooltip:hover .tooltip-content5 {
    opacity: 1;
    pointer-events: auto;
    transition-delay: 0s; }
  .mytooltip .tooltip-text {
    font-size: 14px;
    line-height: 24px;
    display: block;
    padding: 1.31em 1.21em 1.21em 0;
    color: #fff; }
  .mytooltip .tooltip-item2 {
    color: #448aff;
    cursor: pointer;
    z-index: 100;
    position: relative;
    display: inline-block;
    font-weight: 700;
    font-size: 14px;
    transition: background-color 0.3s, color 0.3s, transform 0.3s; }

.tooltip.tooltip-effect-2:hover .tooltip-content {
  transform: perspective(1000px) rotate3d(1, 0, 0, 0deg); }

.tooltip-effect-5 .tooltip-text {
  padding: 1.4em; }

.tooltip-effect-1 .tooltip-content {
  transform: translate3d(0, -10px, 0);
  transition: opacity 0.3s, transform 0.3s;
  color: #fff; }

.tooltip-effect-2 .tooltip-content {
  transform-origin: 50% calc(110%);
  transform: perspective(1000px) rotate3d(1, 0, 0, 45deg);
  transition: opacity 0.2s, transform 0.2s; }

.tooltip-effect-3 .tooltip-content {
  transform: translate3d(0, 10px, 0) rotate3d(1, 1, 0, 25deg);
  transition: opacity 0.3s, transform 0.3s; }

.tooltip-effect-4 .tooltip-content {
  transform-origin: 50% 100%;
  transform: scale3d(0.7, 0.3, 1);
  transition: opacity 0.2s, transform 0.2s; }

.tooltip-effect-5 .tooltip-content {
  width: 180px;
  margin-left: -90px;
  transform-origin: 50% calc(106%);
  transform: rotate3d(0, 0, 1, 15deg);
  transition: opacity 0.2s, transform 0.2s;
  transition-timing-function: ease, cubic-bezier(0.17, 0.67, 0.4, 1.39); }

.tooltip-effect-6 .tooltip-content2 {
  transform: translate3d(0, 10px, 0) rotate3d(1, 1, 1, 45deg);
  transform-origin: 50% 100%;
  transition: opacity 0.3s, transform 0.3s; }
  .tooltip-effect-6 .tooltip-content2 i {
    transform: scale3d(0, 0, 1);
    transition: opacity 0.3s, transform 0.3s; }

.tooltip-effect-7 .tooltip-content2 {
  transform: translate3d(0, 10px, 0);
  transition: opacity 0.3s, transform 0.3s; }
  .tooltip-effect-7 .tooltip-content2 i {
    transform: translate3d(0, 15px, 0);
    transition: opacity 0.3s, transform 0.3s; }

.tooltip-effect-8 .tooltip-content2 {
  transform: translate3d(0, 10px, 0) rotate3d(0, 1, 0, 90deg);
  transform-origin: 50% 100%;
  transition: opacity 0.3s, transform 0.3s; }
  .tooltip-effect-8 .tooltip-content2 i {
    transform: scale3d(0, 0, 1);
    transition: opacity 0.3s, transform 0.3s; }

.tooltip-effect-9 .tooltip-content2 {
  transform: translate3d(0, -20px, 0);
  transition: opacity 0.3s, transform 0.3s; }
  .tooltip-effect-9 .tooltip-content2 i {
    transform: translate3d(0, 20px, 0);
    transition: opacity 0.3s, transform 0.3s; }

.tooltip-effect-6:hover .tooltip-content2 i {
  transform: rotate3d(1, 1, 1, 0deg); }

.tooltip-content2 {
  position: absolute;
  z-index: 9999;
  width: 80px;
  height: 80px;
  padding-top: 25px;
  left: 50%;
  margin-left: -40px;
  bottom: 100%;
  border-radius: 50%;
  text-align: center;
  background: #448aff;
  color: #fff;
  opacity: 0;
  margin-bottom: 20px;
  cursor: default;
  pointer-events: none; }
  .tooltip-content2 i {
    opacity: 0; }
  .tooltip-content2::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin: -7px 0 0 -15px;
    width: 30px;
    height: 20px;
    background: url("../assets/images/tooltip/tooltip1.svg") center center no-repeat;
    background-size: 100%; }

.tooltip-content3 {
  position: absolute;
  background: url("../assets/images/tooltip/shape1.svg") center bottom no-repeat;
  background-size: 100% 100%;
  z-index: 9999;
  width: 200px;
  bottom: 100%;
  left: 50%;
  margin-left: -100px;
  padding: 50px 30px;
  text-align: center;
  color: #fff;
  opacity: 0;
  cursor: default;
  font-size: 14px;
  line-height: 27px;
  pointer-events: none;
  transform: scale3d(0.1, 0.2, 1);
  transform-origin: 50% 120%;
  transition: opacity 0.4s, transform 0.4s;
  transition-timing-function: ease, cubic-bezier(0.6, 0, 0.4, 1); }
  .tooltip-content3::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    left: 50%;
    margin-left: -8px;
    top: 100%;
    background: #00AEEF;
    transform: translate3d(0, -60%, 0) rotate3d(0, 0, 1, 45deg); }

.tooltip-content4 {
  position: absolute;
  z-index: 99;
  width: 360px;
  left: 50%;
  margin-left: -180px;
  bottom: -5px;
  text-align: left;
  background: #448aff;
  opacity: 0;
  font-size: 14px;
  line-height: 27px;
  padding: 1.5em;
  color: #fff;
  border-bottom: 55px solid #0053dd;
  cursor: default;
  pointer-events: none;
  border-radius: 5px;
  transform: translate3d(0, -0.5em, 0);
  transition: opacity 0.3s, transform 0.3s; }
  .tooltip-content4 a {
    color: #2b2b2b; }
  .tooltip-content4 .tooltip-text2 {
    opacity: 0;
    transform: translate3d(0, 1.5em, 0);
    transition: opacity 0.3s, transform 0.3s; }

.tooltip-content5 {
  position: absolute;
  z-index: 9999;
  width: 300px;
  left: 50%;
  bottom: 100%;
  font-size: 20px;
  line-height: 1.4;
  text-align: center;
  font-weight: 400;
  color: #fff;
  background: 0 0;
  opacity: 0;
  margin: 0 0 20px -150px;
  cursor: default;
  pointer-events: none;
  transition: opacity 0.3s 0.3s; }
  .tooltip-content5 span {
    display: block; }
  .tooltip-content5::after {
    content: '';
    bottom: -20px;
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: #448aff transparent transparent;
    border-width: 10px;
    margin-left: -10px; }
  .tooltip-content5 .tooltip-text3 {
    border-bottom: 10px solid #448aff;
    overflow: hidden;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s 0.3s; }
  .tooltip-content5 .tooltip-inner2 {
    background: #2b2b2b;
    padding: 40px;
    transform: translate3d(0, 100%, 0);
    transition: transform 0.3s; }

a.mytooltip {
  font-weight: 700;
  color: #448aff;
  z-index: 9; }

.tooltip-link a {
  margin-left: 10px;
  color: #448aff; }

.tooltip-icon button i {
  margin-right: 0; }

/**====== Tooltip css end ======**/
/**  =====================
      Slider css start
==========================  **/
/*========= Owl-carousel css start ============*/
.owl-theme .owl-nav [class*='owl-']:hover {
  background: #448aff; }

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #448aff; }

/*========= Owl-carousel css end ============*/
/**  =====================
      Animation css start
==========================  **/
.animation-image img {
  margin: 10px auto 20px;
  cursor: pointer; }

/**====== Animation css end ======**/
/**  =====================
      Tree-view css start
==========================  **/
.card-block.tree-view ul {
  display: inherit; }
  .card-block.tree-view ul li {
    display: inherit;
    float: none; }

.jstree-default > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background: url("../assets/images/treeview/throbber.gif") center center no-repeat; }

/**====== Tree-view css end ======**/
/**  =====================
      File Upload css start
==========================  **/
.jFiler-theme-default .jFiler-input-button {
  background-color: #448aff;
  background-image: none;
  color: #fff; }

.jFiler-theme-default .jFiler-input {
  width: 100%; }

.jFiler-input-dragDrop {
  width: 100%;
  background-color: #fafafa; }

.jFiler-items-grid .jFiler-item .jFiler-item-container .jFiler-item-info {
  position: absolute;
  bottom: -10%;
  left: 0;
  width: 100%;
  color: #fff;
  padding: 6px 10px;
  z-index: 9;
  opacity: 0;
  transition: all 0.12s; }

.jFiler-input-choose-btn {
  border: 1px solid #448aff;
  padding: 10px 25px;
  background: #448aff;
  color: #fff !important; }
  .jFiler-input-choose-btn:hover {
    background: #fff;
    border: 1px solid #448aff;
    color: #fff !important; }

.card .jFiler-item-assets ul li {
  margin-right: 0; }

/**====== File Upload css end ======**/
/**  =====================
      Cropper css start
==========================  **/
.img-container,
.img-preview {
  background-color: #f7f7f7;
  width: 100%;
  text-align: center; }

.img-container {
  min-height: 200px;
  max-height: 516px;
  margin-bottom: 20px; }

@media (min-width: 768px) {
  .img-container {
    min-height: 516px; } }

.img-container > img {
  max-width: 100%; }

.docs-preview {
  margin-right: -15px; }

.img-preview {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  overflow: hidden; }
  .img-preview > img {
    max-width: 100%; }

.preview-lg {
  width: 100% !important; }

.preview-md {
  width: 139px;
  height: 78px; }

.preview-sm {
  width: 69px;
  height: 39px; }

.preview-xs {
  width: 35px;
  height: 20px;
  margin-right: 0; }

.docs-data > .input-group {
  margin-bottom: 10px; }
  .docs-data > .input-group > label {
    min-width: 80px; }
  .docs-data > .input-group > span {
    min-width: 50px; }

.docs-buttons > .btn,
.docs-buttons > .form-control,
.docs-buttons > .btn-group {
  margin-right: 5px; }

.docs-toggles > .btn,
.docs-toggles > .dropdown,
.docs-toggles > .btn-group {
  margin-bottom: 10px; }

.docs-tooltip {
  display: block;
  margin: -6px -12px;
  padding: 6px 12px; }
  .docs-tooltip > .icon {
    margin: 0 -3px;
    vertical-align: top; }

.tooltip-inner {
  white-space: normal; }

.btn-upload .tooltip-inner {
  white-space: nowrap; }

@media (max-width: 400px) {
  .btn-group-crop {
    margin-right: -15px !important; }
    .btn-group-crop > .btn {
      padding-left: 5px;
      padding-right: 5px; }
    .btn-group-crop .docs-tooltip {
      margin-left: -5px;
      margin-right: -5px;
      padding-left: 5px;
      padding-right: 5px; } }

.docs-options .dropdown-menu {
  width: 100%; }
  .docs-options .dropdown-menu > li {
    padding: 3px 20px;
    font-size: 14px; }
    .docs-options .dropdown-menu > li:hover {
      background-color: #f7f7f7; }
    .docs-options .dropdown-menu > li > label {
      display: block; }

.docs-cropped .modal-body {
  text-align: center; }
  .docs-cropped .modal-body > img,
  .docs-cropped .modal-body > canvas {
    max-width: 100%; }

.card-block .docs-options .dropdown-menu {
  top: inherit; }

label.btn-upload {
  height: 40px; }

.product-edit .md-tabs .nav-item a {
  padding: 0 0 10px !important;
  color: #37474f; }
  .product-edit .md-tabs .nav-item a .f-20 {
    display: inline-block;
    margin-right: 10px; }

.h-active a {
  color: #1b8bf9 !important;
  font-weight: 500; }

/**  =====================
      User-Profile css start
==========================  **/
.rounded-card img,
.user-img img {
  margin: 0 auto;
  display: block;
  width: 100%; }

.user-img img {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }

.user-about ul li {
  border-top: 1px solid rgba(204, 204, 204, 0.28);
  padding: 10px 0 10px 10px;
  text-align: left; }
  .user-about ul li a {
    font-size: 16px;
    color: #666; }
  .user-about ul li.active a {
    color: #fff; }
  .user-about ul li.active {
    font-weight: 500;
    background: #1b8bf9; }

.card-title,
h5 {
  margin: 0; }

.card-header.followers a {
  font-weight: 500;
  color: rgba(51, 51, 51, 0.85); }

.user-profile {
  padding: 20px 0; }

.follow-btn button:first-child,
.user-about i {
  margin-right: 10px; }

.btn-inline i {
  color: #fff; }

.btn-inline .fb-btn {
  background-color: #3b5998; }

.btn-inline .twitter-btn {
  background-color: #55acee; }

.btn-inline .pinterest-btn {
  background-color: #cb2027; }

.btn-inline .linkedin-btn {
  background-color: #007bb5; }

.btn-inline .dribbble-btn {
  background-color: #ea4c89; }

.user-post {
  font-style: italic; }

.connection-list img,
.contact-user h4,
.contact-user img,
.earn-heading,
.list-inline,
.list-inline li,
.service-header {
  display: inline-block; }

.connection-list img {
  width: 55px;
  height: 55px;
  margin: 5px; }

.border-post {
  border: 1px solid #ccc; }

.earn-sub-header {
  font-size: 15px;
  color: #ccc; }

.btn-inline {
  margin-top: 20px; }

.order-summary .progress {
  margin-bottom: 32px; }

.services .service-btn::after {
  top: 20px;
  right: 15px; }

.services-list {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.11);
  top: 46px; }

.contact-menu i,
.fa-cog,
.services-list i {
  margin-right: 10px; }

.media-heading {
  cursor: pointer; }

h6.media-heading {
  font-weight: 500; }

.contact-details table .fa-star,
.contact-details table .fa-star-o,
.review-star i {
  color: #448aff; }

.review-star i:last-child {
  color: #ccc; }

.card-block.user-info {
  position: absolute;
  width: 100%;
  bottom: 10px;
  padding: 1.25rem; }

.profile-bg-img {
  width: 100%; }

.user-title {
  position: relative;
  bottom: 20px; }
  .user-title h2 {
    color: #fff;
    text-shadow: 1px 1px 4px #373a3c;
    font-size: 20px; }

.profile-image img {
  border: 4px solid #fff; }

.user-info .media-body,
.user-info .media-left {
  display: table-cell;
  vertical-align: middle; }

.cover-btn {
  bottom: 38px;
  right: 35px;
  position: absolute; }

.cover-profile .profile-bg-img {
  margin-bottom: 25px; }

.groups-contact span {
  float: right; }
  .groups-contact span h4 {
    font-size: 18px;
    margin-bottom: 20px; }

.contact-menu {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.11);
  top: 15%;
  right: 10%; }
  .contact-menu .dropdown-item {
    padding: 8px 20px; }

.tab-header {
  margin-bottom: 20px; }

/*====== User-Profile End ======*/
/**  =====================
      User-card css start
==========================  **/
.card-header-img ~ .btn-group i {
  margin-right: 0; }

.card.business-info {
  border-top: none;
  border-left-width: 2px !important;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.05), 0 -2px 1px -2px rgba(0, 0, 0, 0.04), 0 0 0 -1px rgba(0, 0, 0, 0.05);
  transition: all 150ms linear; }
  .card.business-info:hover {
    box-shadow: 0 0 25px -5px #9e9c9e; }

.top-cap-text p {
  padding: 10px 0;
  margin-bottom: 0; }

.user-content {
  text-align: center;
  margin-top: 20px; }
  .user-content h4 {
    font-size: 16px;
    font-weight: 500; }
  .user-content h5 {
    font-size: 14px; }

.img-overlay {
  bottom: 0;
  color: #fff;
  height: 100%;
  width: 100%;
  transition: all 0.4s ease-in-out;
  position: absolute;
  transform: scale(0);
  margin: 0 auto; }
  .img-overlay span {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    text-align: center;
    transform: translate(-50%, -50%); }
    .img-overlay span .btn {
      display: inline-block; }
      .img-overlay span .btn i {
        margin-right: 0; }

.img-hover-main {
  padding: 0 40px; }

.img-hover {
  position: relative;
  margin: 0 auto; }
  .img-hover:hover .img-overlay {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transform: scale(1);
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2;
    transition: all 0.4s ease-in-out; }

.card-header-img img {
  margin: 0 auto;
  display: block; }

.card-header-img h4 {
  margin-top: 30px;
  font-size: 20px;
  font-weight: 500;
  text-align: center; }

.card-header-img h5,
.card-header-img h6 {
  margin-top: 15px;
  font-size: 15px;
  color: #222;
  font-weight: 500;
  text-align: center; }

.simple-cards .btn-group {
  margin: 20px auto 0; }
  .simple-cards .btn-group button {
    margin: 0; }

.simple-cards .user-card {
  padding: 20px 0;
  text-align: center; }
  .simple-cards .user-card .label-icon {
    margin-top: 15px; }
    .simple-cards .user-card .label-icon i {
      font-size: 20px; }
    .simple-cards .user-card .label-icon .badge-top-right {
      margin-left: 3px;
      top: -8px; }

.card-icon {
  display: block;
  margin-bottom: 5px; }

.btn-outline-primary {
  border-color: #448aff;
  font-weight: 500;
  padding: 10px 16px;
  font-size: 15px; }
  .btn-outline-primary:hover {
    background-color: #448aff;
    border-color: #448aff; }

.simple-cards p {
  margin: 20px;
  font-size: 15px; }

.user-profile #edit-btn,
.user-profile #edit-info-btn {
  margin-bottom: 0; }

.card-block ul.list-contacts,
.card-block.groups-contact ul {
  display: block;
  float: none; }
  .card-block ul.list-contacts li,
  .card-block.groups-contact ul li {
    display: block;
    float: none; }

ul.list-contacts .list-group-item a {
  color: #292b2c; }

ul.list-contacts .list-group-item.active a {
  color: #fff; }

.pagination li {
  display: inline-block; }

.card-block.groups-contact {
  margin-bottom: 0; }

.card-block .connection-list {
  margin-bottom: 20px; }

.table button {
  margin-bottom: 0; }

#crm-contact .img-circle,
img.comment-img {
  width: 40px;
  height: 40px; }

.page-link {
  color: #448aff; }

.page-item.active .page-link {
  background-color: #448aff;
  border-color: #448aff; }

#main {
  margin-bottom: 20px; }

/*====== User-card End ======*/
/**  =====================
      Offline css start
==========================  **/
.offline-box iframe {
  width: 100%;
  border: 1px solid #ddd; }

/*==== Offline page css end ====*/
/**  =====================
      Gallery css start
==========================  **/
.gallery-page .card-block {
  margin-bottom: -20px; }

.thumbnail {
  margin-bottom: 20px; }
  .thumbnail .thumb {
    position: relative;
    display: block; }

.card.gallery-desc {
  overflow: hidden; }

.masonry-media {
  overflow: hidden; }
  .masonry-media img {
    transition: all 0.3s ease-in-out; }
    .masonry-media img:hover {
      transform: scale(1.1); }

.masonry-image .masonry-media {
  margin-bottom: 20px; }

figure.effect-goliath {
  background: #448aff; }

/**====== Gallery css end ======**/
/**  =====================
      List-chart css start
==========================  **/
.barchart canvas,
.tristate canvas {
  width: 100% !important; }

.ct-series-a .ct-line {
  stroke: #1ce3bb; }

.ct-series-b .ct-line {
  stroke: rgba(255, 157, 136, 0.62); }

.ct-series-c .ct-line {
  stroke: rgba(79, 84, 103, 0.45); }

.ct-series-d .ct-line {
  stroke: rgba(129, 142, 219, 0.61); }

.ct-series-a .ct-point,
.ct-series-b .ct-point,
.ct-series-c .ct-point,
.ct-series-d .ct-point {
  stroke: rgba(52, 54, 70, 0.47);
  stroke-width: 8px;
  stroke-linecap: round; }

.ct-series-a .ct-slice-donut {
  stroke: #01C0C8; }

.ct-series-b .ct-slice-donut {
  stroke: #83D6DE; }

.ct-series-c .ct-slice-donut {
  stroke: #1ABC9C; }

.ct-series-d .ct-slice-donut {
  stroke: #4F5467; }

/*===== List-chart Css End ====*/
/**  =====================
      Task-list css start
==========================  **/
.task-list select {
  width: 92%; }
  .task-list select .task-list img {
    margin-right: 5px;
    display: inline-block; }

.task-list input {
  width: 80%; }

.task-list-table img {
  width: 40px; }
  .task-list-table img i {
    color: #333;
    margin-right: 5px; }

.task-page td:last-child {
  position: relative; }

.task-page a {
  cursor: pointer; }

.task-page tr td:last-child i {
  margin-right: 10px; }

/*===== Task-list css end ====*/
/**  =====================
      Task-detiails css start
==========================  **/
.thumb-img {
  position: relative;
  display: block; }
  .thumb-img:hover .caption-hover {
    background-color: rgba(0, 0, 0, 0.7);
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
    position: absolute;
    width: 100%;
    height: 100%; }

.caption-hover {
  top: 0;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  transition: all 0.15s ease-in-out; }
  .caption-hover > span {
    top: 38%;
    width: 100%;
    position: absolute;
    text-align: center; }

.media .b-2-primary {
  border: 2px solid #448aff; }

.thumb-block {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  padding: 3px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  transition: border 0.2s ease-in-out; }
  .thumb-block a {
    font-size: 12px; }
  .thumb-block .btn i {
    margin-right: 0; }

.comment-block .btn i {
  margin-right: 0; }

.dropdown-item {
  color: #666;
  padding: 6px 20px; }

.dropdown-item.active,
.dropdown-item:active {
  background-color: #448aff; }

.task-detail-right .counter {
  text-align: center;
  color: #777; }

.task-details .table.table-xs td,
.task-details .table.table-xs th {
  padding: 1rem 0.3rem; }

.assign-user .media-left {
  position: relative; }
  .assign-user .media-left img {
    margin-bottom: 0; }

.photo-table img {
  display: inline-block;
  width: 40px;
  margin-bottom: 5px; }

.v-middle {
  vertical-align: middle; }

.revision-block .form-group {
  margin-bottom: 0; }

.revision-block .btn i {
  margin-right: 0; }

.task-setting .switchery {
  display: block !important;
  float: right; }

.task-setting .form-group {
  margin-bottom: 0; }

.task-attachment i {
  cursor: pointer; }

/**====== Task-details css end ======**/
/**  =====================
      Task-board css start
==========================  **/
.filter-bar .nav,
.filter-bar .nav-item {
  display: inline-block; }

.filter-bar > .navbar {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.08);
  padding: 0.5rem 1rem; }

.navbar-nav .nav-item {
  float: left;
  line-height: 26px; }

.nav-item button i {
  margin-right: 0; }

.filter-bar .navbar-light .navbar-nav .nav-link {
  margin-right: 10px; }

.card-footer .task-list-table,
.card-footer .task-list-table a img {
  display: inline-block; }

.task-board {
  margin-top: 10px;
  float: right; }

.task-board .dropdown {
  display: inline-block; }

p.task-detail {
  margin-bottom: 5px; }

p.task-due {
  margin-bottom: 0; }

.task-right-header-revision,
.task-right-header-status,
.task-right-header-users {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #ccc; }

.taskboard-right-progress,
.taskboard-right-revision,
.taskboard-right-users {
  margin-top: 10px; }

.task-right h6 {
  font-size: 12px; }

.task-right .icofont {
  margin-top: 5px;
  margin-right: 0; }

.taskboard-right-revision .media .media-body .chat-header {
  font-size: 13px; }

.media-left i {
  margin-right: 0; }

.nav-item.nav-grid {
  float: right; }

.faq-progress .progress {
  position: relative;
  background-color: #eeeded;
  height: 10px; }

.faq-progress .progress .faq-text1,
.faq-progress .progress .faq-text2,
.faq-progress .progress .faq-text3,
.faq-progress .progress .faq-text4,
.faq-progress .progress .faq-text5 {
  font-weight: 500;
  margin-right: -37px; }

.faq-progress .progress .faq-bar1,
.faq-progress .progress .faq-bar2,
.faq-progress .progress .faq-bar3,
.faq-progress .progress .faq-bar4,
.faq-progress .progress .faq-bar5 {
  background: #29aecc;
  height: 10px;
  border-radius: 0;
  position: absolute;
  top: 0; }

.faq-progress .progress .faq-bar1 {
  background-color: #ffba57; }

.faq-progress .progress .faq-text1 {
  color: #2196F3; }

.faq-progress .progress .faq-bar2,
.faq-progress .progress .faq-bar5 {
  background-color: #9ccc65; }

.faq-progress .progress .faq-text2,
.faq-progress .progress .faq-text5 {
  color: #4CAF50; }

.faq-progress .progress .faq-bar3 {
  background-color: #ff5252; }

.faq-progress .progress .faq-text3 {
  color: #ff5252; }

.faq-progress .progress .faq-bar4 {
  background-color: #448aff; }

.faq-progress .progress .faq-text4 {
  color: #f57c00; }

.card-faq h4 {
  color: #2196F3; }

.faq-progress .progress {
  margin-bottom: 10px; }

/**====== Tsak-board css end ======**/
/**  =====================
      Issue-list css start
==========================  **/
.issue-list-progress {
  border-bottom: 1px solid #ccc; }

.issue-list-progress .progress {
  position: relative;
  background-color: #eeeded;
  height: 9px;
  width: 100%;
  margin: 20px 0;
  overflow: visible; }

.issue-progress .progress .issue-text1 {
  font-weight: 500;
  position: absolute; }

.issue-progress .progress .issue-bar1 {
  background: #448aff;
  height: 10px;
  border-radius: 0;
  position: absolute;
  top: 0; }

.matrics-issue .sub-title {
  padding-top: 20px;
  padding-bottom: 10px;
  display: block; }

.bg-white {
  background-color: #fff !important; }

.matrics-issue div h6 {
  padding-top: 10px;
  color: #777; }

.dd-w,
.sp-container {
  z-index: 999 !important; }

table.matrics-table tr:first-child td {
  border-top: none !important; }

#issue-list-table > thead > tr > th {
  border-bottom: none; }

.note-card .notes-list {
  margin-bottom: 20px; }

/**====== Issue-list css end ======**/
/**  =====================
      Product css start
==========================  **/
.prod-view {
  overflow: hidden; }
  .prod-view .prod-img {
    position: relative;
    overflow: hidden; }
    .prod-view .prod-img .btn i {
      margin-right: 0; }
  .prod-view:hover .option-hover {
    display: inline-block;
    transition: all 0.2s ease;
    z-index: 1; }

.prod-item .prod-img .option-hover {
  display: none;
  position: absolute;
  right: 0;
  top: 50%;
  left: 0; }
  .prod-item .prod-img .option-hover .btn-icon {
    border-radius: 5px; }

.hvr-shrink {
  display: inline-block;
  vertical-align: middle;
  transition: all 0.3s ease-in-out; }
  .hvr-shrink:hover {
    transform: scale(1.1); }

.prod-img .p-new a {
  position: absolute;
  top: 15px;
  right: 0;
  padding: 8px 13px;
  line-height: 1;
  font-size: 13px;
  text-transform: uppercase;
  border-radius: 2px 0 0 2px;
  background: #00bcd4;
  color: #fff;
  letter-spacing: 1px;
  font-weight: 500; }

.prod-info .br-wrapper {
  margin: 0 auto 20px; }

.prod-info .br-widget {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0; }

.prod-info h4 {
  font-size: 18px;
  margin-bottom: 10px; }

.prod-info .prod-price {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px; }
  .prod-info .prod-price .old-price {
    color: #919aa3;
    text-decoration: line-through;
    font-size: 50%; }

.prod-item .prod-info {
  background: #fff;
  padding: 30px 0 20px; }

.prod-item .br-widget {
  min-height: inherit; }

.prod-img .p-sale {
  position: absolute;
  top: 15px;
  right: 20px;
  width: 40px;
  height: 40px;
  font-size: 11px;
  text-transform: uppercase;
  border-radius: 50%;
  background-color: #ff5252;
  color: #fff;
  font-weight: 800;
  letter-spacing: 1px;
  padding: 11px 4px; }

.prod-info .br-widget {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0; }

.option-icon i {
  height: 25px;
  width: 25px;
  font-size: 25px;
  line-height: 36px; }

/**====== Product css end ======**/
/**  =====================
      Product-details css start
==========================  **/
.product-detail-page {
  border-top: none; }

.product-detail .br-wrapper {
  margin: 10px 0; }

.product-detail .product-detail .btn i {
  margin-right: 0; }

.product-detail .br-widget {
  min-height: 35px;
  margin-top: 0;
  display: block; }

.product-detail .btn-number {
  background-color: #e0e0e0;
  border-color: #d4d4d4;
  border-radius: 0;
  color: #000; }

.product-detail .product-price {
  display: inline-block;
  margin-right: 50px;
  font-size: 24px; }

.product-detail .pro-desc {
  margin-top: 15px;
  margin-bottom: 15px; }

.product-detail .done-task {
  text-decoration: line-through; }

.product-detail hr {
  margin-top: 15px;
  margin-bottom: 15px; }

#small_banner .slick-slide {
  filter: grayscale(100%);
  opacity: 0.5; }

#small_banner img {
  cursor: pointer; }

#small_banner .slick-center {
  filter: grayscale(0);
  opacity: 1; }

#small_banner:hover .slick-prev {
  left: 0;
  transition: all ease-in 0.3s; }

#small_banner:hover .slick-next {
  right: 0;
  transition: all ease-in 0.3s; }

.port_details_all_img {
  overflow: hidden; }

.page-link:hover {
  color: #448aff; }

.slick-prev {
  left: -45px;
  z-index: 1;
  height: 100%;
  background: rgba(0, 0, 0, 0.62);
  width: 4%;
  transition: all ease-in 0.3s; }
  .slick-prev:focus, .slick-prev:hover {
    color: transparent;
    outline: none;
    background: rgba(0, 0, 0, 0.8); }

.brighttheme-icon-sticker:after {
  top: -5px;
  content: "\002016"; }

.slick-next {
  right: -45px;
  z-index: 1;
  height: 100%;
  background: rgba(0, 0, 0, 0.62);
  width: 4%;
  transition: all ease-in 0.3s; }
  .slick-next:focus, .slick-next:hover {
    color: transparent;
    outline: none;
    background: rgba(0, 0, 0, 0.8); }

.counter-input .input-group {
  width: 20%; }

.pro-det-tab .tab-content {
  border: 1px solid #ccc;
  border-top: 0; }

.big_banner .port_big_img {
  margin-bottom: 15px; }

.cd-price .cd-price-month {
  font-size: 64px; }

.product-edit .br-wrapper {
  margin: 10px 0 30px; }

.addcontact .md-content > div ul {
  padding-left: 0; }

/**====== Product-details css end ======**/
/**  =====================
      Search css start
==========================  **/
.search-result .card {
  border-top: none;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15); }
  .search-result .card .card-block h5 {
    font-weight: 500; }
  .search-result .card .card-block p {
    margin-bottom: 0;
    margin-top: 10px;
    line-height: 1.4; }

.seacrh-header {
  margin-top: 20px;
  display: flex;
  align-items: center; }

.search-content {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc; }
  .search-content img {
    width: 120px; }
  .search-content:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0; }

.search-result i {
  float: right; }

.search2 .card-block:first-child {
  padding-top: 0; }

/**====== Search css end ======**/
/**  =====================
      Wizard css start
==========================  **/
.cart-page .wizard > .content > .body {
  width: 100%;
  padding: 0; }

.wizard > .content > .body {
  width: 100%; }

.card .wizard > .steps .current a {
  outline: none;
  border-radius: 5px; }

.payment-card {
  border: 1px solid #ccc;
  border-radius: 0;
  margin-bottom: 15px;
  padding: 20px; }
  .payment-card table {
    margin-bottom: 0; }

.confirmation {
  text-align: center;
  font-size: 80px; }

.confirmation-icon {
  color: #ff5252; }

.width-100 {
  width: 100%; }

.post-input {
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  resize: none; }

.file-upload-lbl {
  max-width: 15px;
  padding: 5px 0 0; }

/**====== Wizard css end ======**/
/**====== Timeline css start ======**/
.post-timelines .media {
  align-items: center; }

a[data-lightbox="example-set"] .img-fluid,
a[data-lightbox="roadtrip"] .img-fluid,
a[data-toggle="lightbox"] .img-fluid {
  margin: 10px 0; }

/**====== Timeline css end ======**/
/**  =====================
      Wall css start
==========================  **/
.post-new-footer {
  border-top: 1px solid #ccc; }
  .post-new-footer i {
    font-size: 18px;
    margin-right: 15px;
    cursor: pointer; }

.inline-editable .btn {
  padding: 10px; }

.inline-editable .input-group .form-control {
  width: 100%; }

.input-group span {
  cursor: pointer; }

.input-group a {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px; }

.msg-send {
  background-color: #f3f3f3; }
  .msg-send:focus {
    background-color: #f3f3f3; }

.wall-dropdown:after {
  position: absolute;
  top: 20px;
  right: 15px;
  cursor: pointer; }

.wall-img-preview {
  display: inline-block;
  text-align: center; }
  .wall-img-preview .wall-item {
    display: block;
    float: left;
    position: relative;
    overflow: hidden;
    border: 2px solid #fff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; }
  .wall-img-preview .wall-item:first-child:nth-last-child(2),
  .wall-img-preview .wall-item:first-child:nth-last-child(2) ~ div {
    width: 50%; }

.wall-img-preview .wall-item > img {
  display: none; }

.wall-img-preview .wall-item:first-child:nth-last-child(2),
.wall-img-preview .wall-item:first-child:nth-last-child(2) ~ div {
  width: 50%; }

.wall-img-preview .wall-item:first-child:nth-last-child(10),
.wall-img-preview .wall-item:first-child:nth-last-child(10) ~ div:not(:last-child),
.wall-img-preview .wall-item:first-child:nth-last-child(11) ~ div:nth-last-of-type(-n+3),
.wall-img-preview .wall-item:first-child:nth-last-child(3),
.wall-img-preview .wall-item:first-child:nth-last-child(3) ~ div,
.wall-img-preview .wall-item:first-child:nth-last-child(4),
.wall-img-preview .wall-item:first-child:nth-last-child(4) ~ div:not(:last-child),
.wall-img-preview .wall-item:first-child:nth-last-child(5),
.wall-img-preview .wall-item:first-child:nth-last-child(5) ~ div:not(:nth-last-of-type(-n+2)),
.wall-img-preview .wall-item:first-child:nth-last-child(6),
.wall-img-preview .wall-item:first-child:nth-last-child(6) ~ div,
.wall-img-preview .wall-item:first-child:nth-last-child(7) ~ div:nth-last-of-type(-n+3),
.wall-img-preview .wall-item:first-child:nth-last-child(9),
.wall-img-preview .wall-item:first-child:nth-last-child(9) ~ div {
  width: 33.333333%; }

.wall-img-preview .wall-item:first-child:nth-last-child(5) ~ div:nth-last-of-type(-n+2) {
  width: 50%; }

.wall-img-preview .wall-item:first-child:nth-last-child(11),
.wall-img-preview .wall-item:first-child:nth-last-child(11) ~ div:not(:nth-last-of-type(-n+3)),
.wall-img-preview .wall-item:first-child:nth-last-child(12),
.wall-img-preview .wall-item:first-child:nth-last-child(12) ~ div,
.wall-img-preview .wall-item:first-child:nth-last-child(7),
.wall-img-preview .wall-item:first-child:nth-last-child(7) ~ div:not(:nth-last-of-type(-n+3)),
.wall-img-preview .wall-item:first-child:nth-last-child(8),
.wall-img-preview .wall-item:first-child:nth-last-child(8) ~ div {
  width: 25%; }

.wall-img-preview .wall-item:first-child:nth-last-child(10) ~ div:nth-child(10),
.wall-img-preview .wall-item:first-child:nth-last-child(4) ~ div:nth-child(4),
.wall-img-preview .wall-item:only-child {
  width: 100%; }

.fb-timeliner h2 {
  background: #448aff;
  color: #fff;
  margin-top: 0;
  padding: 15px;
  font-size: 16px;
  border-radius: 2px;
  font-weight: 300; }

.fb-timeliner ul {
  margin-left: 15px;
  margin-bottom: 20px; }
  .fb-timeliner ul li {
    margin-bottom: 3px; }
    .fb-timeliner ul li a {
      color: #999797;
      border-left: 4px solid #d3d7dd;
      padding-left: 10px;
      padding-top: 3px;
      padding-bottom: 3px;
      display: block; }
      .fb-timeliner ul li a:hover {
        color: #999797;
        border-left: 4px solid #b1b1b1;
        padding-left: 10px; }
    .fb-timeliner ul li.active a {
      color: #7a7a7a;
      border-left: 4px solid #7a7a7a;
      padding-left: 10px; }

/*====== Wall css End ======*/
/**  =====================
      X-editable css start
==========================  **/
.dotted-line-theme .no_edit {
  width: 100% !important; }

.dotted-line-theme .no_edit .i_text {
  font-size: 13px; }

.dotted-line-theme .no_edit {
  padding: 15px 2px; }

.dotted-line-theme .just_edit input[type="radio"] {
  opacity: 0; }

.dotted-line-theme .ibtn_container {
  padding-left: 0;
  margin-top: 2px;
  position: absolute;
  top: 6px;
  z-index: 999;
  width: 120px; }
  .dotted-line-theme .ibtn_container i {
    color: #fff;
    margin-right: 0; }

.dotted-line-theme .i_edit,
.dotted-line-theme .ibtn_container,
.dotted-line-theme .just_edit,
.dotted-line-theme .just_edit input,
.dotted-line-theme .just_edit textarea,
.dotted-line-theme .no_edit,
.dotted-line-theme .no_edit .i_text {
  font-size: 13px; }

.wizard > .content > .body label.error {
  margin-left: 0; }

#msform #progressbar li.active {
  color: #448aff; }

#msform #progressbar li.active:before,
#progressbar li.active:after {
  background: #448aff; }

#msform a {
  color: #448aff;
  font-weight: 500; }

/*====== X-editable css End ======*/
/**  =====================
      Invoice css start
==========================  **/
.invoice-contact {
  display: flex;
  margin-bottom: 30px;
  padding-top: 30px;
  align-items: center; }

.invoice-table {
  border-left: 1px solid #fff;
  padding-left: 20px; }

.invoice-table td,
.invoice-table th {
  border-top: none;
  padding: 3px 0; }

.invoice-table > tbody > tr:last-child > td,
.invoice-table > tfoot > tr:last-child > td {
  padding-bottom: 0; }

.invoice-box h1 {
  font-size: 7rem; }

.invoice-order {
  padding-left: 0; }
  .invoice-order th:first-child {
    padding-left: 0;
    width: 80px; }

.invoice-detail-table th:first-child {
  width: 450px;
  text-align: left; }

.invoice-detail-table thead th {
  text-align: center; }

.invoice-detail-table td {
  vertical-align: middle;
  text-align: center; }
  .invoice-detail-table td:first-child {
    text-align: left; }

.invoice-total {
  background: #f3f3f3;
  padding: 30px 0; }

.invoice-total td,
.invoice-total th {
  text-align: right; }

.invoice-total td {
  padding-left: 30px; }

.invoive-info {
  display: flex;
  margin-bottom: 30px; }
  .invoive-info h6 {
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 500;
    color: #757575; }

.invoice-total tbody {
  padding-right: 20px;
  float: right; }

.dropdown-menu i {
  margin-right: 5px; }

/**====== Invoice css end ======**/
.grid-layout span {
  display: block;
  border: 1px solid #ddd;
  padding: 10px;
  margin: 5px 0; }

.vertical-alignment .row {
  padding: 15px 0;
  min-height: 10rem;
  border: 1px solid #ddd; }

.inte-benifits {
  border: 1px solid #ddd; }
  .inte-benifits ul {
    list-style: square;
    padding-left: 20px; }

/**  =====================
      Version css start
==========================  **/
.version .card-block {
  padding: 0; }

.card.version .card-header .card-header-right {
  background-color: #fff;
  top: 8px; }

.version .nav {
  display: block; }

.version .nav li {
  display: block;
  padding: 10px 20px;
  cursor: pointer; }
  .version .nav li:hover {
    background-color: #f6f7f7; }
  .version .nav li a {
    color: #666; }
  .version .nav li span {
    position: absolute;
    right: 20px; }

.version .navigation-header a {
  color: #999; }

.support-btn {
  padding: 0 20px 20px; }

.introjs-helperNumberLayer {
  background: linear-gradient(to bottom, #ff6c6c 0%, #ff5252 100%); }

.dd-handle,
.dd3-content {
  font-weight: 500; }

.img-radius {
  border-radius: 50%; }

.version .nav li:first-child {
  border-top: 1px solid #ddd;
  color: #666; }

/**====== Version css end ======**/
/**====== Date-dropper css ======**/
.dd-w,
.sp-container {
  z-index: 99; }

/*====== Color css ends ======= */
/**  =====================
      Session Timeout css start
==========================  **/
#sessionTimeout-dialog .close {
  display: none; }

/*====== Session Timeout css ends ======= */
/*====== Warning css ends ======= */
.image-cropper-container {
  margin-top: 10px; }

/*====== Json form css start =======*/
.alpaca-field img {
  width: 250px; }

/*====== Json form css ends =======*/
/*====== Sticky css start =======*/
.arrow_box {
  z-index: 0; }

@media only screen and (max-width: 575px) {
  .sticky-card {
    margin-bottom: 250px; } }

/*====== Sticky css ends =======*/
/*====== Ready to use css start ======*/
.j-pro .j-label {
  font-weight: 500; }

/*====== Ready to use css ends ======*/
.ms-container {
  width: 100%; }

#main-chat .page-error i,
.full-calender .page-error i {
  font-size: 35px;
  border-radius: 6px;
  padding: 6px 9px; }

@media only screen and (min-width: 788px) {
  #main-chat .page-error,
  .full-calender .page-error {
    display: none; } }

@media only screen and (max-width: 786px) {
  #main-chat .page-body,
  .full-calender .page-body {
    display: none; } }

@media only screen and (max-width: 575px) {
  .card .card-header .card-header-right.job-compney-btn {
    position: relative;
    float: left;
    right: 0; }
  .clndr .event-listing {
    display: none; }
  .clndr .clndr-grid {
    width: 100%; }
    .clndr .clndr-grid .day-number {
      padding: 3px 4px !important; } }

.dotted-line-theme .no_edit {
  border-bottom: 1px dotted #448aff; }

.handsontable td,
.handsontable th {
  color: #37474f; }

#map,
.radial-bar:after {
  z-index: 99; }

.content-group {
  padding: 10px; }

.card-sub .card-title {
  margin-bottom: 10px;
  font-weight: 500; }

.card-sub .card-text {
  color: #737373; }

.long-press-popup {
  position: fixed;
  bottom: 15px;
  left: 275px;
  right: 35px;
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 8px;
  margin: 0;
  font-size: 50px; }
  .long-press-popup li {
    margin-right: 15px;
    margin-top: 15px;
    margin-bottom: 15px; }

.jFiler.jFiler-theme-default .jFiler-input {
  border: none;
  border-bottom: 2px solid #ccc;
  box-shadow: none;
  border-radius: 0;
  height: 40px; }

.jFiler.jFiler-theme-default .jFiler-input-button {
  border-radius: 4px;
  height: auto;
  padding: 7px 16px; }

#main-chat .page-error i,
.full-calender .page-error i {
  font-size: 35px;
  border-radius: 6px;
  padding: 6px 9px; }

@media only screen and (min-width: 788px) {
  #main-chat .page-error,
  .full-calender .page-error {
    display: none; } }

@media only screen and (max-width: 786px) {
  #main-chat .page-body,
  .full-calender .page-body {
    display: none; } }

.wizard > .content {
  min-height: 36em;
  overflow-y: auto; }
  .wizard > .content > .body input.form-control {
    border-top: none;
    border-right: none;
    border-left: none; }

.tabledit-input:disabled {
  display: none; }

/**  =====================
      Foo-table css start
==========================  **/
.footable .pagination > .active > a,
.footable .pagination > .active > a:focus,
.footable .pagination > .active > a:hover,
.footable .pagination > .active > span,
.footable .pagination > .active > span:focus,
.footable .pagination > .active > span:hover {
  background-color: #448aff;
  border-color: #448aff; }

.footable .pagination > li > a,
.footable .pagination > li > span {
  color: #222; }

.footable-details.table,
.footable.table,
table.footable > tfoot > tr.footable-paging > td > span.label {
  margin-bottom: 0; }

table.footable-paging-center > tfoot > tr.footable-paging > td {
  padding-bottom: 0; }

/**====== Foo-table css end ======**/
/**  =====================
      Handson css start
==========================  **/
.make-me-red {
  color: red; }

.scroll-container .wtHolder {
  height: 350px; }

.scroll-container #autocomplete .wtHolder,
.scroll-container #buttons .wtHolder,
.scroll-container #checkbox .wtHolder,
.scroll-container #chromaJS .wtHolder,
.scroll-container #comments .wtHolder,
.scroll-container #configuration .wtHolder,
.scroll-container #context .wtHolder,
.scroll-container #copyPaste .wtHolder,
.scroll-container #dateDate .wtHolder,
.scroll-container #drag .wtHolder,
.scroll-container #dropdown .wtHolder,
.scroll-container #highlighting .wtHolder,
.scroll-container #jQuery .wtHolder,
.scroll-container #nonEditable .wtHolder,
.scroll-container #numericData .wtHolder,
.scroll-container #paginating .wtHolder,
.scroll-container #populating .wtHolder,
.scroll-container #readOnly .wtHolder,
.scroll-container #searching .wtHolder,
.scroll-container #select .wtHolder,
.scroll-container #timeData .wtHolder,
.scroll-container #validation .wtHolder {
  height: auto !important; }

.handson-pagination {
  margin-top: 30px; }

.currentRow {
  background-color: #F9F9FB; }

.currentCol {
  background-color: #E7E8EF; }

.contact-table tr td:nth-child(n-2) {
  text-align: center; }

.contact-table tr td:last-child {
  position: relative; }
  .contact-table tr td:last-child .dropdown-menu {
    top: 52px; }

/**====== Handson css end ======**/
#checkbox-select tr td.select-checkbox,
#checkbox-select tr th.select-checkbox {
  padding-left: 30px; }
  #checkbox-select tr td.select-checkbox:after, #checkbox-select tr td.select-checkbox:before,
  #checkbox-select tr th.select-checkbox:after,
  #checkbox-select tr th.select-checkbox:before {
    left: 15px; }

@media only screen and (min-width: 1367px) and (max-width: 1700px) {
  .timeline-dot:after, .timeline-dot:before {
    left: 34px; }
  .timeline-dot .social-timelines:before {
    left: 37px; }
  .timeline-dot .social-timelines-left:after {
    right: -30px; }
  .timeline-dot .timeline-right .card {
    margin-left: 30px; } }

@media only screen and (max-width: 1366px) {
  .to-do-list {
    margin-bottom: 15px; } }

@media only screen and (min-width: 1279px) and (max-width: 1500px) {
  .cd-horizontal-timeline {
    margin: 0 auto; }
  #chartdiv {
    height: 440px; }
  .social-card-1 .card-block-big {
    padding: 1.3em; }
  #morris-extra-area {
    height: 400px !important; }
  .widget-chat-box .receive-chat,
  .widget-chat-box .send-chat {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .similar-job .media {
    display: inline-block; }
    .similar-job .media .media-body {
      margin-top: 20px; } }

@media only screen and (min-width: 1200px) and (max-width: 1258px) {
  .user-head {
    text-align: center; }
    .user-head .user-face {
      margin: 0 auto; }
    .user-head .user-name {
      display: none; } }

@media only screen and (min-width: 1200px) and (max-width: 1366px) {
  .timeline-dot .card {
    margin-left: 50px; }
  .timeline-dot:after, .timeline-dot:before {
    left: 35px; }
  .timeline-dot .social-timelines:before {
    left: 38px; }
  .timeline-dot .social-timelines-left:after {
    right: -50px; } }

@media only screen and (max-width: 1199px) {
  .nav-tabs.md-tabs.tab-timeline li a {
    font-size: 14px; }
  #design-wizard .steps li a {
    padding: 1em 0.5em; }
  #draggablePanelList [class*="col-"] .card-sub {
    margin-bottom: 30px; }
  #draggablePanelList [class*="col-"]:last-child .card-sub {
    margin-bottom: 0; }
  .user-head {
    justify-content: center; }
  .user-body {
    min-height: auto !important; }
  .mail-box-head {
    text-align: center; }
    .mail-box-head form {
      float: inherit; }
  #external-events h6 {
    margin-top: 0;
    margin-bottom: 20px; }
  .just_edit .input-group {
    width: 100% !important; }
  .dotted-line-theme .ibtn_container {
    top: 10px;
    position: inherit; }
  .users-card [class*="col-"] {
    margin-bottom: 20px; }
    .users-card [class*="col-"]:last-child {
      margin-bottom: 0; }
  .timeline-btn {
    text-align: center;
    width: 100%;
    right: 0; }
  .social-timeline-left {
    position: inherit;
    top: 0;
    margin-right: 0; }
  .timeline-icon {
    position: absolute;
    margin-left: 0; }
  .social-timelines:before {
    left: 35px; }
  .timeline-dot:after, .timeline-dot:before {
    left: 32px; }
  .user-box .social-client-description {
    border-bottom: 1px solid #ddd; }
  .user-box .f-right {
    float: none;
    display: block; }
  .main-timeline {
    padding: 10px; }
  .main-timeline:after,
  .main-timeline:before {
    margin-left: -94px;
    margin-top: 25px;
    display: none; }
  .cd-timeline-content::before {
    top: 15px; }
  .cd-date,
  .cd-details {
    display: block;
    padding-left: 20px !important;
    padding-top: 0 !important; }
  .cd-details {
    margin-bottom: 20px; }
  .cd-timeline-content p {
    margin-bottom: 0; }
  .clock-widget .basic-alarm {
    display: none; }
  .weather-card-2 .weather-card-temp {
    padding: 0; }
  .weather-card-2 .card-footer {
    padding-top: 20px;
    padding-bottom: 20px; }
  .user-activity-card .card-block-big {
    padding-top: 15px;
    padding-bottom: 15px; } }

@media only screen and (max-width: 992px) {
  .animation-image img {
    margin: 20px auto 0; }
  .animation-type {
    text-align: center; }
  .nav-tabs.md-tabs.tab-timeline li a {
    font-size: 16px; } }

@media only screen and (min-width: 992px) {
  .btn-blog {
    text-align: right; }
  .basic-list,
  .card-block [class*="col-"]:nth-last-child(-n+3) .card.user-card {
    margin-bottom: 0; }
  .menu-rtl .breadcrumb-item + .breadcrumb-item::after {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #868e96;
    content: "/"; }
  .menu-rtl .breadcrumb-item + .breadcrumb-item::before {
    content: none; } }

@media only screen and (max-width: 991px) {
  .messages-content {
    border-right: none; } }

@media only screen and (min-width: 801px) and (max-width: 915px) {
  .grid figure {
    min-width: auto; } }

@media only screen and (min-width: 768px) {
  #draggableWithoutImg [class*="col-"]:last-child .m-t-20 {
    margin-top: 0; } }

@media only screen and (min-width: 768px) and (max-width: 872px) {
  .author-details .header-right li {
    margin-right: 40px; }
  .recent-candidate-card .recent-contain {
    margin-left: 0; }
  .author-details img ~ .dot1,
  .author-details img ~ .dot2 {
    top: 30%; } }

@media only screen and (max-width: 768px) {
  .card-block.list-tag ul li:last-child {
    margin-bottom: 20px; }
  .inline-order-list {
    margin-top: 0; }
  .recent-candidate-card .recent-contain {
    margin-left: 0; }
  .mail-body-content .form-group:nth-child(2) .form-control {
    margin-bottom: 20px; }
  .mail-body-content .form-group:nth-child(2) .col-md-6:last-child .form-control {
    margin-bottom: 0; }
  .dataTables_paginate .pagination {
    float: inherit;
    text-align: center;
    display: inline-block;
    margin-top: 10px !important; }
  div.dataTables_wrapper div.dataTables_info {
    display: inherit; }
  .wizard > .steps > ul > li {
    float: inherit;
    width: 100%; }
  .gallery-page .grid {
    padding: 0; }
  figure.effect-steve h2 {
    margin-top: 0; }
  .cover-profile .profile-bg-img {
    margin-bottom: 0; }
  .card-block.user-info {
    position: inherit;
    text-align: center;
    background-color: #d6d6d6;
    margin-bottom: 20px; }
  .user-info .media-body,
  .user-info .media-left {
    display: block; }
  .cover-btn {
    position: inherit;
    float: none; }
    .cover-btn .btn:first-child {
      margin-right: 10px; }
  .user-info .user-title {
    position: initial;
    margin-bottom: 10px;
    margin-top: 10px; }
  .nav-tabs.md-tabs.tab-timeline li a {
    font-size: 14px; }
  .user-content h4 {
    font-size: 1rem; }
  .user-content h5 {
    margin-right: 0;
    font-size: 14px; }
  .top-cap-text p {
    padding: 0; }
    .top-cap-text p:last-child {
      margin-bottom: 0; }
  .user-card .btn span {
    font-size: 13px; }
  .toolbar-page [class*="col-"] {
    margin-bottom: 30px; }
    .toolbar-page [class*="col-"]:last-child {
      margin-bottom: 0; }
  .invoice-contact {
    margin-top: 20px;
    margin-bottom: 0; }
  .lng-dropdown {
    display: none; }
  .group-widget .card-block-big {
    height: 110px; }
  .whether-card .card-footer {
    text-align: center; }
  .switchery {
    margin-bottom: 20px; }
  .switchery-large,
  .switchery-large ~ .switchery-default,
  .switchery-small {
    margin-bottom: 0; }
  .js-dynamic-disable,
  .js-dynamic-enable {
    display: block;
    width: 100%; }
  .js-dynamic-disable {
    margin-bottom: 20px; }
  .border-checkbox-section .border-checkbox-group,
  .checkbox-color,
  .radio-inline {
    display: block; }
  .checkbox-fade {
    display: inline-block; }
  .messages-content {
    border-right: none; } }

@media only screen and (max-width: 767px) {
  .signup-card {
    position: inherit; }
  .similar-job .media {
    display: inline-block;
    text-align: center;
    border-bottom: 1px solid #f1f1f1; }
    .similar-job .media .media-body {
      margin-top: 20px; }
  .cal-event .inverse-card p:after {
    width: 100%; }
  .recent-candidate-card .recent-contain {
    margin-left: 10px; }
  .author-details .header-right {
    text-align: left;
    margin-top: 20px; }
  .post-new-contain textarea:focus {
    border: none; }
  .wizard.vertical > .content,
  .wizard.vertical > .steps {
    width: 100%; }
  .wizard {
    overflow: visible; }
  .comment-input .input-group {
    display: block; }
    .comment-input .input-group i {
      text-align: center;
      width: 100%; }
    .comment-input .input-group input {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px; } }

@media only screen and (max-width: 640px) {
  .dt-button {
    margin-bottom: 5px; }
  .email-read .card-header h6 {
    float: none;
    margin-top: 10px; }
  .email-read .media {
    display: inline-block; }
  .email-read .media.m-b-20 {
    margin-bottom: 0; }
  .email-read .mail-img [class*="col-"] {
    margin-bottom: 30px; }
    .email-read .mail-img [class*="col-"]:last-child {
      margin-bottom: 0; } }

@media only screen and (max-width: 573px) {
  .author-details .header-right li {
    margin-right: 20px;
    display: block;
    margin-top: 20px; }
  .toolbar-page [class*="col-"] .sub-title {
    text-align: center; }
  #task-container li {
    width: 100%; }
  .nav-tabs .nav-link {
    text-align: center; }
  .email-card .nav-tabs .nav-link {
    text-align: left; }
  .search-content {
    display: block;
    text-align: center; }
    .search-content img {
      margin-top: 20px; }
  .seacrh-header {
    margin-top: 0; }
    .seacrh-header .input-group {
      margin-bottom: 0; }
  .payment-card .text-right {
    text-align: left !important; }
  .payment-tabs .md-tabs .nav-item,
  .payment-tabs .md-tabs .nav-item .nav-item,
  .payment-tabs .md-tabs .nav-item + .nav-item {
    width: calc(100% / 1) !important; }
  .jp-card {
    min-width: 225px !important; }
  .payment-form {
    padding: 20px 0 0; }
  .modal-mob-btn [class*="col-"]:first-child {
    margin-bottom: 20px; }
  .location-mob-btn .btn {
    margin-bottom: 10px;
    margin-right: 0; }
  .job-cards .media {
    display: inline-block;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px; }
    .job-cards .media p {
      margin-bottom: 0; }
  .invoice-btn-group .m-r-20 {
    margin-right: 0; }
  .table-card .row-table {
    display: block;
    text-align: center; }
    .table-card .row-table i {
      font-size: 28px;
      margin-bottom: 10px;
      display: inline-block; }
  .table-card .row-table .br {
    border: none;
    border-bottom: 1px solid #ccc; }
  .table-card .row-table > [class*=col-] {
    display: block; }
  .card-block-big {
    padding: 1em; }
    .card-block-big #barchart,
    .card-block-big #barchart2 {
      display: inline-block;
      margin-bottom: 10px; }
  .mobile-inputs [class*="col-"]:first-child {
    margin-bottom: 20px; }
  .pagination-lg .page-link {
    padding: 0.75rem 0.65rem; }
  .mobtn {
    padding: 10px; }
  .task-sale-card {
    text-align: center; } }

@media only screen and (max-width: 460px) {
  .fc-toolbar .fc-right .fc-button-group {
    margin-right: 0; }
  .social-msg a {
    margin-bottom: 10px; }
    .social-msg a:last-child {
      margin-bottom: 0; }
  .bd-example-modal .modal-footer {
    justify-content: center; }
    .bd-example-modal .modal-footer button {
      margin-right: 0; }
  .invoice-total tbody {
    float: none; }
  .timeline-right .card {
    margin-left: 20px; }
  .user-box .media-left {
    padding-right: 10px; }
  figure.effect-moses h2 {
    width: 60%;
    height: 70%;
    padding: 10px;
    font-size: 25px; }
  figure.effect-moses p {
    padding: 5px; } }

@media only screen and (max-width: 480px) {
  .j-pro .j-checkbox-toggle {
    padding-bottom: 30px; }
  .main-body .page-wrapper {
    padding: 10px; }
  .j-forms .divider-text span {
    white-space: pre-line;
    display: block;
    line-height: 1.8; }
  .j-forms .divider-text {
    height: auto;
    margin-top: 30px;
    margin-bottom: 10px; }
  .j-forms .content {
    padding: 0 10px; }
  .comment-block .media-left {
    padding-right: 10px; }
  .breadcrumb-page .card {
    text-align: center; }
  div.dataTables_wrapper div.dataTables_filter input {
    width: 150px; }
  #dt-live-dom .form-control,
  #footer-search tfoot .form-control,
  #footer-select tfoot .form-control,
  #form-input-table .form-control,
  .search-api .form-control {
    width: 65%;
    padding: 0.5rem 6px; }
  .grid figure {
    min-width: auto; }
  .mytooltip .tooltip-content {
    width: 310px;
    margin: 0 0 20px -90px; }
    .mytooltip .tooltip-content:after {
      margin-left: -90px; }
  .product-detail .txt-muted.d-inline-block {
    margin-top: 10px; }
    .product-detail .txt-muted.d-inline-block ~ .f-right {
      float: none;
      display: block;
      margin-top: 10px; }
  .mob-product-btn {
    text-align: center; }
    .mob-product-btn .btn-primary {
      margin-right: 0;
      display: block;
      margin: 10px auto; } }

@media only screen and (max-width: 420px) {
  #msform {
    width: 320px;
    margin: 20px auto; }
  .ms-container {
    width: 240px; }
    .ms-container .ms-selectable,
    .ms-container .ms-selection {
      width: 40%; }
  .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0; } }

@media only screen and (min-width: 576px) and (max-width: 1366px) {
  .job-card.card-columns {
    column-count: 2; } }

@media only screen and (max-width: 576px) {
  .md-tabs .nav-item a {
    margin-bottom: 5px; }
  .add-task-card .to-do-list h6 span {
    display: inline-block; } }

/**  =====================
      Dark layout css start
==========================  **/
.pcoded[layout-type="dark"] .thumb-block {
  border: 1px solid #5c5b5b; }

.pcoded[layout-type="dark"] .b-t-default,
.pcoded[layout-type="dark"] .b-t-theme,
.pcoded[layout-type="dark"] .post-new-footer,
.pcoded[layout-type="dark"] .version .nav li:first-child {
  border-top: 1px solid #5c5b5b; }

.pcoded[layout-type="dark"] .b-b-default,
.pcoded[layout-type="dark"] .b-b-theme,
.pcoded[layout-type="dark"] .job-cards .media,
.pcoded[layout-type="dark"] .search-content {
  border-bottom: 1px solid #5c5b5b; }

.pcoded[layout-type="dark"] .md-tabs .nav-item a {
  color: #fff; }
  .pcoded[layout-type="dark"] .md-tabs .nav-item a.active, .pcoded[layout-type="dark"] .md-tabs .nav-item a:focus, .pcoded[layout-type="dark"] .md-tabs .nav-item a:hover {
    color: #448aff; }
  .pcoded[layout-type="dark"] .md-tabs .nav-item a.active:focus, .pcoded[layout-type="dark"] .md-tabs .nav-item a.active:hover {
    color: #448aff; }

.pcoded[layout-type="dark"] .nav-tabs {
  border-bottom: 1px solid #5c5b5b; }
  .pcoded[layout-type="dark"] .nav-tabs .nav-link:focus, .pcoded[layout-type="dark"] .nav-tabs .nav-link:hover {
    border-color: #5c5b5b; }

.pcoded[layout-type="dark"] .tab-below {
  border-top: 1px solid #5c5b5b;
  border-bottom: none; }
  .pcoded[layout-type="dark"] .tab-below .nav-link {
    margin-top: 1px; }

.pcoded[layout-type="dark"] .scroll-list li {
  background-color: #545454;
  color: #fff; }
  .pcoded[layout-type="dark"] .scroll-list li:nth-child(even) {
    background-color: #616161; }

.pcoded[layout-type="dark"] .page-link {
  background-color: #5c5b5b;
  border-color: #303030; }

.pcoded[layout-type="dark"] .jqpagination input {
  margin: 0;
  height: 30px; }

.pcoded[layout-type="dark"] .nav-tabs .nav-item.show .nav-link,
.pcoded[layout-type="dark"] .nav-tabs .nav-link.active {
  color: #fff;
  background-color: #4f4f4f;
  border-color: #ddd #ddd #4f4f4f; }

.pcoded[layout-type="dark"] .nav-tabs.tab-below .nav-item.show .nav-link,
.pcoded[layout-type="dark"] .nav-tabs.tab-below .nav-link.active {
  border-color: #4f4f4f #ddd #ddd; }

.pcoded[layout-type="dark"] .modal-content {
  background-color: #545454; }
  .pcoded[layout-type="dark"] .modal-content .modal-header {
    border-bottom: 1px solid #424242; }
  .pcoded[layout-type="dark"] .modal-content .modal-footer {
    border-top: 1px solid #424242; }

.pcoded[layout-type="dark"] .br-theme-bars-square .br-widget a {
  background-color: #545454; }

.pcoded[layout-type="dark"] .jstree-anchor.jstree-hovered {
  background-color: #5c5b5b; }

.pcoded[layout-type="dark"] .dd-handle {
  color: #fff; }

.pcoded[layout-type="dark"] .dd3-content {
  color: #fff;
  border: 1px solid #424242;
  background-color: #5c5b5b; }

.pcoded[layout-type="dark"] .img-thumbnail {
  background-color: #424242;
  border: 1px solid #303030; }

.pcoded[layout-type="dark"] .dotted-line-theme .no_edit:hover {
  background-color: #5c5b5b;
  border-bottom: 1px solid #424242; }

.pcoded[layout-type="dark"] .icon-list-demo i {
  color: #fff; }

.pcoded[layout-type="dark"] .form-radio label {
  color: #fff; }

.pcoded[layout-type="dark"] .bootstrap-tagsinput {
  background-color: #5c5b5b; }

.pcoded[layout-type="dark"] #reportrange {
  background-color: #5c5b5b !important; }

.pcoded[layout-type="dark"] .select2-container--default .select2-selection--multiple,
.pcoded[layout-type="dark"] .select2-dropdown {
  background-color: #545454; }

.pcoded[layout-type="dark"] .ms-container .ms-selectable,
.pcoded[layout-type="dark"] .ms-container .ms-selection {
  background-color: #545454; }
  .pcoded[layout-type="dark"] .ms-container .ms-selectable li.ms-elem-selectable,
  .pcoded[layout-type="dark"] .ms-container .ms-selection li.ms-elem-selectable {
    color: #fff; }
  .pcoded[layout-type="dark"] .ms-container .ms-selectable li.disabled,
  .pcoded[layout-type="dark"] .ms-container .ms-selection li.disabled {
    background-color: #545454;
    opacity: 0.5; }

.pcoded[layout-type="dark"] .wizard > .content {
  background-color: #4f4f4f; }

.pcoded[layout-type="dark"] .wizard > .steps .disabled a,
.pcoded[layout-type="dark"] .wizard > .steps .disabled a:active,
.pcoded[layout-type="dark"] .wizard > .steps .disabled a:hover {
  background-color: #545454; }

.pcoded[layout-type="dark"] .wizard > .steps .done a {
  background-color: #424242; }

.pcoded[layout-type="dark"] .table-bordered {
  border: 1px solid #5c5b5b; }
  .pcoded[layout-type="dark"] .table-bordered td,
  .pcoded[layout-type="dark"] .table-bordered th {
    border: 1px solid #5c5b5b; }

.pcoded[layout-type="dark"] a.dt-button.disabled,
.pcoded[layout-type="dark"] button.dt-button.disabled,
.pcoded[layout-type="dark"] div.dt-button.disabled {
  background-image: none;
  opacity: 0.5; }

.pcoded[layout-type="dark"] .fixedHeader-floating {
  background-color: #5c5b5b; }

.pcoded[layout-type="dark"] table.DTFC_Cloned tfoot,
.pcoded[layout-type="dark"] table.DTFC_Cloned thead {
  background-color: #545454; }

.pcoded[layout-type="dark"] .dt-responsive input {
  background-color: #5c5b5b;
  border: 1px solid #4f4f4f; }

.pcoded[layout-type="dark"] .footable-details.table-striped > tbody > tr:nth-child(odd),
.pcoded[layout-type="dark"] .footable.table-striped > tbody > tr:nth-child(odd) {
  background: #4f4f4f; }

.pcoded[layout-type="dark"] .footable-details.table > thead > tr > td,
.pcoded[layout-type="dark"] .footable-details.table > thead > tr > th,
.pcoded[layout-type="dark"] .footable.table > thead > tr > td,
.pcoded[layout-type="dark"] .footable.table > thead > tr > th {
  border-bottom: 2px solid #424242; }

.pcoded[layout-type="dark"] .c3-tooltip td,
.pcoded[layout-type="dark"] .morris-hover {
  background-color: #424242; }

.pcoded[layout-type="dark"] #chart_Candlestick rect,
.pcoded[layout-type="dark"] #chart_Combo rect,
.pcoded[layout-type="dark"] #chart_Donut rect,
.pcoded[layout-type="dark"] #chart_Exploading rect,
.pcoded[layout-type="dark"] #chart_Threshold rect,
.pcoded[layout-type="dark"] #chart_Waterfall rect,
.pcoded[layout-type="dark"] #chart_area rect,
.pcoded[layout-type="dark"] #chart_bar rect,
.pcoded[layout-type="dark"] #chart_bubble rect,
.pcoded[layout-type="dark"] #chart_stacking rect {
  fill: #4f4f4f !important; }

.pcoded[layout-type="dark"] .auth-box .form-control {
  background-color: #fff; }

.pcoded[layout-type="dark"] .chat-header {
  color: #fff; }

.pcoded[layout-type="dark"] .social-msg a span {
  color: #f2f2f2; }

.pcoded[layout-type="dark"] .list-group-item {
  background: #4f4f4f; }

.pcoded[layout-type="dark"] ul.list-contacts a {
  color: #fff; }

.pcoded[layout-type="dark"] .prod-item .prod-info {
  background: #4f4f4f; }

.pcoded[layout-type="dark"] .email-card .user-body {
  background: #4f4f4f; }
  .pcoded[layout-type="dark"] .email-card .user-body .page-list li.mail-section a {
    color: #fff; }
    .pcoded[layout-type="dark"] .email-card .user-body .page-list li.mail-section a.active, .pcoded[layout-type="dark"] .email-card .user-body .page-list li.mail-section a:hover {
      background: rgba(255, 255, 255, 0.2); }
  .pcoded[layout-type="dark"] .email-card .user-body .label-list a {
    color: #fff; }

.pcoded[layout-type="dark"] .email-card .mail-body-content tr:hover {
  background: rgba(255, 255, 255, 0.2); }

.pcoded[layout-type="dark"] .email-card .mail-body-content .unread a {
  color: #fff; }

.pcoded[layout-type="dark"] .email-card .mail-body-content .read a {
  color: #e6e5e5; }

.pcoded[layout-type="dark"] .email-card .user-mail h6 {
  color: #fff; }

.pcoded[layout-type="dark"] .wall-img-preview .wall-item {
  border: 2px solid #4f4f4f; }

.pcoded[layout-type="dark"] .msg-send {
  background: #565656; }

.pcoded[layout-type="dark"] .filter-bar > .navbar {
  background: #4f4f4f; }

.pcoded[layout-type="dark"] .navbar-light .navbar-nav .active > .nav-link,
.pcoded[layout-type="dark"] .navbar-light .navbar-nav .nav-link,
.pcoded[layout-type="dark"] .navbar-light .navbar-nav .nav-link.active,
.pcoded[layout-type="dark"] .navbar-light .navbar-nav .nav-link.show,
.pcoded[layout-type="dark"] .navbar-light .navbar-nav .show > .nav-link {
  color: #fff; }

.pcoded[layout-type="dark"] #task-container li {
  background: #5c5b5b;
  color: #fff; }

.pcoded[layout-type="dark"] .note-card .note-write {
  background: -webkit-linear-gradient(top, #757575 0%, #8f8e8e 5%) 0 0;
  background-size: 100% 35px; }

.pcoded[layout-type="dark"] .note-card .note-box-aside {
  border-right: 1px solid #5c5b5b; }

.pcoded[layout-type="dark"] .invoice-table {
  border-left: none; }

.pcoded[layout-type="dark"] .invoice-total,
.pcoded[layout-type="dark"] .thead-default th {
  background: #424242; }

.pcoded[layout-type="dark"] #external-events .fc-event,
.pcoded[layout-type="dark"] .danger-nav,
.pcoded[layout-type="dark"] .fc-state-default,
.pcoded[layout-type="dark"] .info-nav,
.pcoded[layout-type="dark"] .light-nav-border,
.pcoded[layout-type="dark"] .primary-nav,
.pcoded[layout-type="dark"] .success-nav,
.pcoded[layout-type="dark"] .warning-nav {
  background-color: #5c5b5b;
  color: #fff; }

.pcoded[layout-type="dark"] .jFiler-input-dragDrop,
.pcoded[layout-type="dark"] .jFiler-theme-default .jFiler-input {
  background-color: #4a4a4a; }

.pcoded[layout-type="dark"] .danger-nav .navbar-varient-submenu,
.pcoded[layout-type="dark"] .info-nav .navbar-varient-submenu,
.pcoded[layout-type="dark"] .light-nav-border .navbar-varient-submenu,
.pcoded[layout-type="dark"] .primary-nav .navbar-varient-submenu,
.pcoded[layout-type="dark"] .success-nav .navbar-varient-submenu,
.pcoded[layout-type="dark"] .warning-nav .navbar-varient-submenu {
  background-color: #545454;
  color: #fff; }

.pcoded[layout-type="dark"] .danger-nav .navbar-varient-submenu a:hover,
.pcoded[layout-type="dark"] .info-nav .navbar-varient-submenu a:hover,
.pcoded[layout-type="dark"] .light-nav-border .navbar-varient-submenu a:hover,
.pcoded[layout-type="dark"] .primary-nav .navbar-varient-submenu a:hover,
.pcoded[layout-type="dark"] .success-nav .navbar-varient-submenu a:hover,
.pcoded[layout-type="dark"] .warning-nav .navbar-varient-submenu a:hover {
  background-color: #5c5b5b; }

.pcoded[layout-type="dark"] .card.version .card-header .card-header-right {
  background-color: #4f4f4f;
  color: #fff; }

.pcoded[layout-type="dark"] .version .nav li:first-child {
  color: #fff; }

.pcoded[layout-type="dark"] .version .nav li:hover {
  background-color: #5c5b5b; }

.pcoded[layout-type="dark"] .version .nav li a {
  color: #fff; }

.pcoded[layout-type="dark"] .main-timeline {
  background-color: #4f4f4f; }
  .pcoded[layout-type="dark"] .main-timeline .bg-white {
    background-color: #5c5b5b !important; }
  .pcoded[layout-type="dark"] .main-timeline .cd-timeline-content {
    background-color: #5c5b5b; }
    .pcoded[layout-type="dark"] .main-timeline .cd-timeline-content h6,
    .pcoded[layout-type="dark"] .main-timeline .cd-timeline-content i,
    .pcoded[layout-type="dark"] .main-timeline .cd-timeline-content p,
    .pcoded[layout-type="dark"] .main-timeline .cd-timeline-content span {
      color: #fff; }

.pcoded[layout-type="dark"] table.dataTable tbody tr {
  background-color: #686868; }

.pcoded[layout-type="dark"] table.dataTable.display tbody tr.odd, .pcoded[layout-type="dark"] table.dataTable.stripe tbody tr.odd {
  background-color: #828282; }

.pcoded[layout-type="dark"] table.dataTable.display tbody tr.even > .sorting_1, .pcoded[layout-type="dark"] table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
  background-color: #8f8e8e; }

.pcoded[layout-type="dark"] table.dataTable.display tbody tr.odd > .sorting_1, .pcoded[layout-type="dark"] table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
  background-color: #757575; }

.pcoded[layout-type="dark"] .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(68, 138, 255, 0.1); }

.pcoded[layout-type="dark"] .chat-card .received-chat .msg {
  background-color: #5c5b5b; }

.pcoded[layout-type="dark"] .chat-card .send-chat .msg {
  background-color: #4f4f4f; }

.pcoded[layout-type="dark"] .chat-card .chat-saprator span {
  background: #4f4f4f;
  color: #fff; }
